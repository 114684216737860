import moment from "moment/moment";
import { CONSTANT_NAME } from "./PropertyResolver";

export const getRandomClassName = () => {
  const classNames = [
    "grid__badge blue__color",
    "grid__badge pink__color",
    "grid__badge green__color",
  ];
  const randomIndex = Math.floor(Math.random() * classNames.length);
  return classNames[randomIndex];
};

export function capitalizeFirstLetter(str) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function truncateString(str, maxLength) {
  if (!str) return "";
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + "...";
}

export function formatPrice(price) {
  if (!price) {
    return price;
  }
  const intPrice = parseInt(price);
  return price == 0 ? "Free" : `₹${intPrice?.toFixed(2)}`;
}

export function timeAgo(dateString) {
  if (!dateString) return dateString;
  return moment(dateString).fromNow();
}

export function formatDateWithTime(createdAt) {
  if (!createdAt) {
    return createdAt;
  }
  return moment(createdAt).format("DD, MMMM YYYY h:mm A");
}

export function getUserRole(roleNumber) {
  switch (roleNumber) {
    case 1:
      return CONSTANT_NAME.ADMIN;
    case 2:
      return CONSTANT_NAME.STUDENT;
    case 3:
      return CONSTANT_NAME.INSTRUCTOR;
    default:
      return CONSTANT_NAME.INVALID_ROLE;
  }
}

export function convertMinutesToHours(minutes) {
  if(!minutes){
    return minutes;
  }
  const hours = (minutes / 60).toFixed(2); // Convert to hours and round to 2 decimal places
  return `${hours} hrs`;
}
