import * as Yup from "yup";

export const profileUpdateValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  userName: Yup.string().required("User Name is required"),
  phoneNumber: Yup.string()
    .required("Phone Number is required")
    .matches(/^[0-9]+$/, "Phone number must contain only numbers"), // Allows only digits
  skill: Yup.string().required("Skill/Occupation is required"),
  bio: Yup.string().required("Bio is required"),
});
