import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../../services/axiosRequestHandler";
import { ERROR_MESSAGE } from "../../../utils/PropertyResolver";
import { API_END_POINT } from "../../../utils/ApiEndPoints";
import toast from "react-hot-toast";

// Initial state for the slice
const resourceState = {
  isLoading: false,
  error: null,
  resourcesList: [],
  resourceCourseList:[],
  resourceSubTopicList:[],
  resourceTechnologyList:[]
};

// Creating a Resource
export const createResource = createAsyncThunk(
  "resource/createResource",
  async (params, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.CREATE_RESOURCE, params);
      if (response?.status === 200) {
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FAILED_CREATE_RESOURCE);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FAILED_CREATE_RESOURCE);
    }
  }
);

// Fetching resources list
export const getResourcesList = createAsyncThunk(
  "resource/getResourcesList",
  async (params, thunkAPI) => {
    try {
        console.log('api')
      const response = await GET(API_END_POINT.GET_RESOURCES_LIST, params);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

export const getResourceCourseList = createAsyncThunk(
  "resource/getResourceCourseList",
  async (params, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.RESOURCE_COURSE_LIST_DATA, params);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

export const getResourceSubTopicList = createAsyncThunk(
  "resource/getResourceSubTopicList",
  async (params, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.RESOURCE_SUBTOPICS_BY_COURSE_NAME, params);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

export const getResourceTechnologyList = createAsyncThunk(
  "resource/getResourceTechnologyList",
  async (params, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.RESOURCE_TECHNOLOGY_LIST, params);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

// Resource slice containing both actions
const resourceSlice = createSlice({
  name: "resourceSlice",
  initialState: resourceState,
  reducers: {
    resetResourceState: (state) => {
      state.resourceData = null;
      state.error = null;
    },
    resetResourcesListState: (state) => {
      state.resourcesList = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Handle createResource actions
    builder
      .addCase(createResource.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createResource.fulfilled, (state, action) => {
        state.isLoading = false;
        state.resourceData = action.payload || null;
      })
      .addCase(createResource.rejected, (state, action) => {
        state.isLoading = false;
        state.resourceData = null;
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      });

    // Handle getResourcesList actions
    builder
      .addCase(getResourcesList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResourcesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.resourcesList = action.payload || [];
      })
      .addCase(getResourcesList.rejected, (state, action) => {
        state.isLoading = false;
        state.resourcesList = [];
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      }) .addCase(getResourceCourseList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResourceCourseList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.resourceCourseList = action.payload || [];
      })
      .addCase(getResourceCourseList.rejected, (state, action) => {
        state.isLoading = false;
        state.resourceCourseList = [];
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      }).addCase(getResourceSubTopicList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResourceSubTopicList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.resourceSubTopicList = action.payload || [];
      })
      .addCase(getResourceSubTopicList.rejected, (state, action) => {
        state.isLoading = false;
        state.resourceSubTopicList = [];
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      }).addCase(getResourceTechnologyList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResourceTechnologyList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.resourceTechnologyList = action.payload || [];
      })
      .addCase(getResourceTechnologyList.rejected, (state, action) => {
        state.isLoading = false;
        state.resourceTechnologyList = [];
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      })
  },
});

export const { resetResourceState, resetResourcesListState } = resourceSlice.actions;

export default resourceSlice.reducer;
