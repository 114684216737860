import { combineReducers } from "@reduxjs/toolkit";
import courseSlice from "./slices/coureses/courseSlice";
import subTopicSlice from "./slices/subTopics/subTopicSlice";
import lessonsSlice from "./slices/lessons/lessonSlice";
import authSlice, { logout } from "./slices/auth/authSlice";
import categorySlice from "./slices/courseCategory/categorySlice";
import userProfileSlice from "./slices/userProfile/userProfileSlice";
import assignmentSlice from "./slices/assignment/assignmentSlice";
import resourceSlice from "./slices/resources/resourceSlice";
import registrationSlice from "./slices/registrationForm/registraionFormSlice";






const appReducer = combineReducers({
  global: "global",
  course: courseSlice,
  subTopic: subTopicSlice,
  lessons: lessonsSlice,
  auth: authSlice,
  category: categorySlice,
  userProfile:userProfileSlice,
  assignment: assignmentSlice,
  resource: resourceSlice,
  registration: registrationSlice
});

const rootReducer = (state, action) => {
  //clear all data in redux store in initial
  if (action.type === logout.type) state = undefined;
  return appReducer(state, action);
};

export default rootReducer;
