import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../../services/axiosRequestHandler";
import { ERROR_MESSAGE } from "../../../utils/PropertyResolver";
import { API_END_POINT } from "../../../utils/ApiEndPoints";
import toast from "react-hot-toast";

const lessonState = {
  isLoading: false,
  error: null,
  lessonsData: [],
};

// Fetching lessons by subtopic ID
export const getLessonsBySubTopicId = createAsyncThunk(
  "lessons/getLessonsBySubTopicId",
  async (params, thunkAPI) => {
    try {
      const response = await GET(
        API_END_POINT.GET_LESSONS_BY_SUBTOPIC_ID,
        params
      );
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FAILED_FETCH_LESSONS);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FAILED_FETCH_LESSONS);
    }
  }
);

export const createLesson = createAsyncThunk(
  "createLesson",
  async (payload, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.CREATE_LESSON, payload);
      if (response?.status === 200) {
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

const lessonSlice = createSlice({
  name: "lessonSlice",
  initialState: lessonState,
  reducers: {
    resetLessonsState: (state) => {
      state.lessonsData = [];
      state.error = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getLessonsBySubTopicId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLessonsBySubTopicId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lessonsData = action.payload || [];
      })
      .addCase(getLessonsBySubTopicId.rejected, (state, action) => {
        state.isLoading = false;
        state.lessonsData = [];
        state.error = action.payload || ERROR_MESSAGE.FAILED_FETCH_LESSONS;
      })
      .addCase(createLesson.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createLesson.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createLesson.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      });
  },
});

export const { resetLessonsState } = lessonSlice.actions;

export default lessonSlice.reducer;
