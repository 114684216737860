import React from 'react'
import { convertMinutesToHours, formatPrice, getRandomClassName } from '../../utils/CommonMethod'
import Rating from '../rating/Rating';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';

function HorizontalCourseCard({data}) {
  const randomClassName = getRandomClassName();


  return (
    <>
      <div className="gridarea__wraper gridarea__wraper__2 gridarea__course__list">
          <div className="gridarea__img">
          <Link to={`/sub-topic/${data?.id}`}>
              <img loading="lazy" src={data?.thumbnail_url} alt="grid" />
           </Link>
            <div className="gridarea__small__button">
              <div className={randomClassName}>{data?.category}</div>
            </div>
            <div className="gridarea__small__icon">
              <a href="#">
                <i className="icofont-heart-alt"></i>
              </a>
            </div>
          </div>
          <div className="gridarea__content">
            <div className="gridarea__list">
              <ul>
                <li>
                  <i className="icofont-book-alt"></i> {data?.total_lessons} Lesson
                </li>
                
                <li>
                  <i className="icofont-clock-time"></i>{convertMinutesToHours(data?.total_hrs)} 
                </li>
              </ul>
            </div>
            <div className="gridarea__heading">
              <h3>
              <Link to={`/sub-topic/${data?.id}`}>
                {data?.title}
              </Link>
              </h3>
            </div>
            <div className="gridarea__price">
            {formatPrice(data?.price)} <del>/ {formatPrice(data?.previous_price)}</del>
            </div>
            <div className="gridarea__bottom">
              <div className="gridarea__bottom__left">
              <Link to={`/instructor-profile/${data?.instructor_info?.id}`}>
                  <div className="gridarea__small__img">
                  {data?.instructor_info?.profile_url ? (
                    <img
                      loading="lazy"
                      src={data?.instructor_info?.profile_url}
                      alt="grid"
                    />
                  ) : (
                    <Avatar
                      size="30"
                      round="20px"
                      textSizeRatio={1.5}
                      color="#A2A9B6"
                      name={data?.instructor_info?.name}
                      fon
                    />
                  )}
                    <div className="gridarea__small__content">
                      <h6>{data?.instructor_info?.name}</h6>
                    </div>
                  </div>
                </Link>

                <div className="gridarea__star">
                <Rating ratingCount={data?.courseRating || 5} />
                <span>({data?.ratingCount || 5})</span>
              </div>
              </div>

              <div className="gridarea__details">
              <Link to={`/sub-topic/${data?.id}`}>
                  Know Details
                  <i className="icofont-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default HorizontalCourseCard
