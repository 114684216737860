import React from "react";

const Rating = ({ ratingCount=0, maxRating = 5 }) => {
  // Full stars
  const fullStars = Array.from(
    { length: Math.floor(ratingCount) },
    (_, index) => <i key={index} className="icofont-ui-rating"></i>
  );

  // Empty stars
  const emptyStars = Array.from(
    { length: maxRating - Math.floor(ratingCount) },
    (_, index) => (
      <i key={index + fullStars.length} className="icofont-ui-rate-blank"></i>
    )
  );

  return (
    <>
      {fullStars}
      {emptyStars}
    </>
  );
};

export default Rating;
