import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST, DELETE } from "../../../services/axiosRequestHandler";
import { ERROR_MESSAGE } from "../../../utils/PropertyResolver";
import { API_END_POINT } from "../../../utils/ApiEndPoints";
import toast from "react-hot-toast";

// Initial state for the slice
const registrationState = {
  isLoading: false,
  error: null,
  registrationsList: [],
  registrationData: null,
};

// Creating a Registration
export const createRegistration = createAsyncThunk(
  "createRegistration",
  async (payload, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.CREATE_REGISTRATION, payload);
      if (response?.status === 200) {
        toast.success(response?.response?.data?.message);
        return response?.response?.data?.data;
      } else {
        toast.error(response?.response?.data?.message);
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

// Fetching all registrations
export const getRegistrationsList = createAsyncThunk(
  "getRegistrationsList",
  async (_, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.GET_REGISTRATIONS_LIST);
      if (response?.status === 200) {
        return response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

// Soft deleting a registration
export const deleteRegistration = createAsyncThunk(
  "deleteRegistration",
  async (id, thunkAPI) => {
    try {
      const response = await DELETE(`${API_END_POINT.DELETE_REGISTRATION}/${id}`);
      if (response?.status === 200) {
        toast.success("Registration deleted successfully.");
        return id; // Return the deleted ID
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

// Registration slice containing both actions
const registrationSlice = createSlice({
  name: "registrationSlice",
  initialState: registrationState,
  reducers: {
    resetRegistrationState: (state) => {
      state.registrationData = null;
      state.error = null;
    },
    resetRegistrationsListState: (state) => {
      state.registrationsList = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Handle createRegistration actions
    builder
      .addCase(createRegistration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createRegistration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.registrationData = action.payload || null;
      })
      .addCase(createRegistration.rejected, (state, action) => {
        state.isLoading = false;
        state.registrationData = null;
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      });

    // Handle getRegistrationsList actions
    builder
      .addCase(getRegistrationsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRegistrationsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.registrationsList = action.payload || [];
      })
      .addCase(getRegistrationsList.rejected, (state, action) => {
        state.isLoading = false;
        state.registrationsList = [];
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      });

    // Handle deleteRegistration actions
    builder
      .addCase(deleteRegistration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteRegistration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.registrationsList = state.registrationsList.filter(reg => reg.id !== action.payload);
      })
      .addCase(deleteRegistration.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      });
  },
});

export const { resetRegistrationState, resetRegistrationsListState } = registrationSlice.actions;

export default registrationSlice.reducer;
