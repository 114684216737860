import * as Yup from "yup";

export const resourceValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Resource title is required")
    .min(3, "Title should be at least 3 characters long"),
  technology: Yup.string()
    .required("Technology is required")
    .min(2, "Technology should be at least 2 characters long"),
  download_url: Yup.string()
    .required("Download URL is required")
    .url("Invalid URL format"),
  course_id: Yup.string()
    .required("Course is required"),
  subtopicId: Yup.string()
    .required("Subtopic is required"),
});
