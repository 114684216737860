import React from "react";
import { useParams } from "react-router-dom";
import { CONSTANT_NAME } from "../../utils/PropertyResolver";
import Ebooks from "./component/Ebooks";
import CheatSheet from "./component/CheatSheet";
import GoogleAd from "../../shared-components/googleAd/GoogleAd";

function Resources() {
  const { category } = useParams();
  
  return <>
  <GoogleAd
          client={CONSTANT_NAME.ADSENCE_CLIENT_ID}
          slot={CONSTANT_NAME.ADSENCE_SLOT_ID}
          format="auto"
          responsive="true"
          />
  {category === CONSTANT_NAME.EBOOKS ? <Ebooks /> : <CheatSheet />}</>;
}

export default Resources;
