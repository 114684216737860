import React from 'react'
import loading from "../../assets/images/companyLogo.png"
function MainLoader() {
  return (
    <>
      <div id="back__preloader">
        <div id="back__circle_loader"></div>
        <div class="back__loader_logo">
            <img loading="lazy" src={loading} alt="Preload" />
        </div>
    </div>
    </>
  )
}

export default MainLoader
