export const ERROR_MESSAGE = {
  SOMETHING_WENT_WRONG: "Something went wrong",
  COURSElIST_NOT_FOUND: "Unable to find course list",
  FAILED_FETCH_SUBTOPIC: "Unable to find sub-course list",
  FAILED_FETCH_LESSONS: "Unable to find lessons",
  REGISTER_FAILED: "Unable to register user",
  LOGIN_FAILED: "Unable to login ",
  UNABLE_TO_CREATE_COURSE: "Unable to create course",
  FAILED_CREATE_JOB_CATEGORY: "Unable to create job category",
  FAILED_FETCH_JOB_CATEGORIES: "Unable to fetch job category list",
  ALLOW_IMG: "Only .jpg and .png file types are allowed.",
  FAILED_CREATE_RESOURCE:"Unable to create resources",
  CONFIRM_PASSWORD_NOT_MATCHED:"Password and confirm password not match"
};

export const CONSTANT_NAME = {
  ADSENCE_CLIENT_ID:"ca-pub-3764101642829009",
  ADSENCE_SLOT_ID:"8971515840",
  EMAIL_ID: "apnicoding72@gmail.com",
  MOBILE_NUMBER: "+91 7292932052",
  ADDRESS: "Sec-58, Nodia, UP",
  PAGE_ONE: 1,
  PER_PAGE_LIMIT_EIGHT: 8,
  COURSE_TITLE_TRUNCATE_LENGTH: 200,
  TOKEN: "token",
  HORIZONTAL:'horizontal',
  VERTICAL:'vertical',
  EBOOKS:'ebooks',
  ADMIN:"admin",
  STUDENT:"student",
  INSTRUCTOR: "instructor",
  INVALID_ROLE: "invalid role"
};

export const urlMappings = {
  S3_BASE_URL: "",
  FACEBOOK_URL: "https://www.facebook.com/Ervivekkumar72/?_rdr",
  INSTAGRAM_URL: "https://www.instagram.com/apni.coding/",
  TWITTER_URL: "https://www.facebook.com/Ervivekkumar72/?_rdr",
  YOUTUBE_URL: "https://www.youtube.com/channel/UCeFHr8q8fDrgQt4d3EFEKIw",
  GIT_HUB_URL: "https://github.com/apni-coding",
  LINKED_IN: "https://www.linkedin.com/in/vivek-kumar-b48165258/",
  YOUTUBE_VIDEO_URL: "https://youtu.be/h5SzlFtUUec?si=uP73_1sXxwzQnSZG",
  HOW_TO_SOLVE_ASSIGNMENT:"https://youtu.be/h5SzlFtUUec?si=uP73_1sXxwzQnSZG"
};
