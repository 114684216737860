import React from 'react'
import ResourceForm from './component/ResourceForm'

function CreateResource() {
  return (
    <>
      <ResourceForm />
    </>
  )
}

export default CreateResource
