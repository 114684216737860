import React from 'react';
import './ButtonLoader.css'; 
function ButtonLoader() {
  return (
    <div className="button-loader">
      <div className="spinner"></div>
    </div>
  );
}

export default ButtonLoader;
