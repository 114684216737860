import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";

import ButtonLoader from "../../../shared-components/loader/ButtonLoader";
import MainLoader from "../../../shared-components/loader/MainLoader";
import {
  getAllSubTopicByCourseId,
  getSubTopicByCourseId,
} from "../../../redux/slices/subTopics/subTopicSlice";
import { resourceValidationSchema } from "../../../validationSchema/createResource";
import {
  allCourseList,
  getCourseList,
} from "../../../redux/slices/coureses/courseSlice";
import CreateResource from "../CreateResource";
import { createResource } from "../../../redux/slices/resources/resourceSlice";

function ResourceForm() {
  const dispatch = useDispatch();
  const [selectedCourseId, setSelectedCourseId] = useState("");

  const isLoading = false ;



  const handleCourseChange = (e, setFieldValue) => {
    const courseId = e.target.value;
    console.log(courseId);
    setSelectedCourseId(courseId);
    setFieldValue("course_id", courseId);
    setFieldValue("subtopicId", ""); // Reset subtopic when course changes
  };

  const handleSubmit = async (values, { resetForm }) => {
    const payload = {
      title: values.title,
      technology: values.technology,
      download_url: values.download_url,
      course: values.course_id,
      subtopic: values.subtopicId,
      is_cheat_sheet: values.isCheatSheet,
    };

    try {
      await dispatch(createResource(payload)).unwrap();
      resetForm();
      setSelectedCourseId("");
    } catch (error) {
      // Handle error
    }
  };

  return (
    <>
      {isLoading && <MainLoader />}
      <div className=" " id="resource__form" role="tabpanel">
        <div className="col-xl-12">
          <div className="loginarea__wraper dashboard__content__wraper">
            <div className="login__heading">
              <h5 className="login__title">Create Resource</h5>
            </div>

            <Formik
              initialValues={{
                title: "",
                technology: "",
                download_url: "",
                course_id: "",
                subtopicId: "",
              }}
              validationSchema={resourceValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Course Name<span className="error">*</span>
                        </label>
                        <Field
                          className="common__login__input"
                          name="course_id"
                          onChange={(e) => handleCourseChange(e, setFieldValue)}
                          placeholder="Enter course name"
                        />
                        <ErrorMessage
                          name="course_id"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Subtopic Name<span className="error">*</span>
                        </label>
                        <Field
                          className="common__login__input"
                          name="subtopicId"
                          placeholder="Enter subtopic name"
                          disabled={!selectedCourseId}
                        />

                        <ErrorMessage
                          name="subtopicId"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Resource Title<span className="error">*</span>
                        </label>
                        <Field
                          className="common__login__input"
                          name="title"
                          placeholder="Resource Title"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Technology<span className="error">*</span>
                        </label>
                        <Field
                          className="common__login__input"
                          name="technology"
                          placeholder="Technology (e.g., Node.js)"
                        />
                        <ErrorMessage
                          name="technology"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Download URL<span className="error">*</span>
                        </label>
                        <Field
                          className="common__login__input"
                          name="download_url"
                          placeholder="Download URL"
                        />
                        <ErrorMessage
                          name="download_url"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">Is cheat sheet </label>
                        <Field name="isCheatSheet">
                          {({ field }) => (
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="isCheatSheet"
                                {...field}
                                checked={field.value}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="isCheatSheet"
                              >
                                Is Cheat Sheet?
                              </label>
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>

                  <div className="login__button">
                    <button
                      className="default__button"
                      type="submit"
                      style={{ width: "100%" }}
                      disabled={isLoading || isSubmitting}
                    >
                      {isLoading ? <ButtonLoader /> : "Submit Resource"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResourceForm;
