import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../../services/axiosRequestHandler";
import { ERROR_MESSAGE } from "../../../utils/PropertyResolver";
import { API_END_POINT } from "../../../utils/ApiEndPoints";
import toast from "react-hot-toast";

// Define the initial state
const subTopicState = {
  isLoading: false,
  error: null,
  subTopicData: [],
  subTopicWithLesson: {},
  subTopicByCourseId: [],
};

// fetching all the subtopics
export const getSubTopicList = createAsyncThunk(
  "getSubTopicList",
  async (params, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.GET_ALL_SUBTOPICS, params);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FAILED_FETCH_SUBTOPIC);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FAILED_FETCH_SUBTOPIC);
    }
  }
);

export const getSubTopicByCourseId = createAsyncThunk(
  "getSubTopicByCourseId",
  async (params, thunkAPI) => {
    try {
      const response = await GET(
        API_END_POINT.GET_SUBTOPIC_BY_COURSE_ID,
        params
      );
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FAILED_FETCH_SUBTOPIC);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FAILED_FETCH_SUBTOPIC);
    }
  }
);

export const getAllSubTopicByCourseId = createAsyncThunk(
  "getAllSubTopicByCourseId",
  async (params, thunkAPI) => {
    try {
      const response = await GET(
        API_END_POINT.GET_ALL_SUBTOPICS_COURSE_ID,
        params
      );
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FAILED_FETCH_SUBTOPIC);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FAILED_FETCH_SUBTOPIC);
    }
  }
);

export const createSubTopic = createAsyncThunk(
  "createSubTopic",
  async (payload, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.CREATE_SUBTOPIC, payload);
      if (response?.status === 200) {
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

const subTopicSlice = createSlice({
  name: "subTopicSlice",
  initialState: subTopicState,
  reducers: {
    resetSubTopicWithLessonState: (state) => {
      return (state.subTopicWithLesson = {});
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSubTopicList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubTopicList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subTopicData = action?.payload || [];
      })
      .addCase(getSubTopicList.rejected, (state, action) => {
        state.isLoading = false;
        state.subTopicData = [];
        state.error = action.payload || ERROR_MESSAGE.FAILED_FETCH_SUBTOPIC;
      });

    builder
      .addCase(getSubTopicByCourseId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubTopicByCourseId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subTopicWithLesson = action?.payload || {};
      })
      .addCase(getSubTopicByCourseId.rejected, (state, action) => {
        state.isLoading = false;
        state.subTopicWithLesson = {};
        state.error = action.payload || ERROR_MESSAGE.FAILED_FETCH_SUBTOPIC;
      });
    builder
      .addCase(createSubTopic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSubTopic.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createSubTopic.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getAllSubTopicByCourseId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSubTopicByCourseId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subTopicByCourseId = action.payload;
      })
      .addCase(getAllSubTopicByCourseId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.subTopicByCourseId = [];
      });
  },
});

export const { resetSubTopicWithLessonState } = subTopicSlice.actions;

export default subTopicSlice.reducer;
