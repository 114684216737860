import React, { useEffect, useState } from "react";
import ResourceCard from "../../../shared-components/resourceCard/ResourceCard";
import EbookFilter from "./EbookFilter";
import MainLoader from "../../../shared-components/loader/MainLoader";
import { useDispatch, useSelector } from "react-redux";
import { getResourcesList } from "../../../redux/slices/resources/resourceSlice";

function CheatSheet() {
  const dispatch = useDispatch();

  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedSubTopic, setSelectedSubTopic] = useState("");
  const [selectedTechnologies, setSelectedTechnologies] = useState("");


  const { resourcesList, isLoading } = useSelector((state) => state.resource);
  useEffect(() => {
    const param = `?course_id=${selectedCourse}&subtopic_id=${selectedSubTopic}&is_cheat_sheet=${true}&technology=${selectedTechnologies}`;
    dispatch(getResourcesList(param));
  }, [selectedCourse, selectedSubTopic, selectedTechnologies]);
  return (
    <>
      {isLoading && <MainLoader />}
      <div className="resourceCard ">
        <h3>E-Books Lists</h3>
        <hr className="hr" />
        <EbookFilter
          selectedCourse={selectedCourse}
          setSelectedCourse={setSelectedCourse}
          selectedSubTopic={selectedSubTopic}
          setSelectedSubTopic={setSelectedSubTopic}
          data={resourcesList}
          selectedTechnologies={selectedTechnologies}
          setSelectedTechnologies={setSelectedTechnologies}
        />
        <ResourceCard data={resourcesList} />
      </div>
    </>
  );
}

export default CheatSheet;
