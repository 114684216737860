import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Loadable from "./shared-components/lazyLoading/Loadable";
import Dashboard from "./components/dashboard/Dashboard";
import Auth from "./components/auth/Auth";
import AllBlog from "./components/blog/component/AllBlog";
import Login from "./components/auth/component/Login";
import './App.css'
import CourseByFilter from "./components/courseByFilter/CourseByFilter";
import Resources from "./components/resources/Resources";
import UpdatePassword from "./components/auth/component/UpdatePassword";
import { CONSTANT_NAME } from "./utils/PropertyResolver";
import ScrollToTop from "./shared-components/scrollTop/ScrolltoTop";

const ContactUs = Loadable(() => import("./components/contact-us/ContactUs"));
const Home = Loadable(() => import("./components/home/Home"));
const Footer = Loadable(() => import("./pages/footer/Footer"));
const Header = Loadable(() => import("./pages/header/Header"));
const TopbarSection = Loadable(() => import("./pages/header/TopbarSection"));
const LightMode = Loadable(() => import("./shared-components/LightMode/LightMode"));
const MainLoader = Loadable(() => import("./shared-components/loader/MainLoader"));
const ProtectedRoute = Loadable(() => import("./ProtectedRoutes"));
const AboutUs = Loadable(() => import("./components/about-us/AboutUs"));
const Lesson = Loadable(()=> import("./components/lesson/Lesson"));
const AllCourse = Loadable(()=> import("./components/all-course/AllCourse"));
const CourseDetails = Loadable(()=> import("./components/sub-topics/CourseDetails"));



function App() {
  return (
    <Router>
      <TopbarSection />
      <ScrollToTop/>
      <Header />
      <Routes>
        <Route path="/" exact  element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/all-course" element={<AllCourse />} />
        <Route path="/lesson/:id" element={<Lesson />} />
        <Route path="/sub-topic/:id" element={<CourseDetails />} />
        <Route path="/login" element={<Auth />} />
        <Route path="/blog" element={<AllBlog />} />
        <Route path="/course/:filter" element={<CourseByFilter />} />
        <Route path="/resources/:category" element={<Resources />} />
        <Route path="/reset-password" element={<UpdatePassword />} />

        


        
        {/* <Route path="/register" element={<Register />} /> */}
        <Route
          path="/user-dashboard"
          element={<ProtectedRoute element={<Dashboard />} roles={[CONSTANT_NAME.ADMIN, CONSTANT_NAME.STUDENT, CONSTANT_NAME.INSTRUCTOR]} />}
        />







        <Route path="/unauthorized" element={<div>Unauthorized</div>} />
      
        <Route
          path="/admin"
          element={<ProtectedRoute element={<Home />} roles={["admin"]} />}
        />
      </Routes>
      <Footer />
      <LightMode />
    </Router>
  );
}

export default App;
