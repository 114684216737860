import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCourseList } from "../../../redux/slices/coureses/courseSlice";
import {
  getAllSubTopicByCourseId,
  getSubTopicList,
} from "../../../redux/slices/subTopics/subTopicSlice";
import {
  getResourceCourseList,
  getResourceSubTopicList,
  getResourceTechnologyList,
} from "../../../redux/slices/resources/resourceSlice";
import { capitalizeFirstLetter } from "../../../utils/CommonMethod";

export default function EbookFilter({
  selectedCourse,
  setSelectedCourse,
  selectedSubTopic,
  setSelectedSubTopic,
  selectedTechnologies,
  setSelectedTechnologies,
}) {
  const dispatch = useDispatch();
  const {
    resourceCourseList: courseList,
    resourceSubTopicList: subTopicData,
    resourceTechnologyList: technology,
  } = useSelector((state) => state.resource);
  console.log("course list", subTopicData);

  // Handle course selection
  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    setSelectedSubTopic("");
  };

  // Handle subtopic selection
  const handleSubTopicChange = (event) => {
    setSelectedSubTopic(event.target.value);
  };

  const handleTechnologiesChange = (event) => {
    setSelectedTechnologies(event.target.value)
  };
  useEffect(() => {
    dispatch(getResourceCourseList());
    dispatch(getResourceTechnologyList());
  }, []);

  useEffect(() => {
    const params = `/${selectedCourse}`;
    if (selectedCourse) {
      dispatch(getResourceSubTopicList(params));
    }
  }, [selectedCourse]);

  return (
    <div className="row">
      <div className="col-md-3 mb-4">
        <select
          className="form-select"
          aria-label="Filter by technologies"
          value={selectedTechnologies}
          onChange={handleTechnologiesChange}
        >
          <option value="">Filter by technologies</option>
          {technology?.map((item, index) => (
            <option value={item?.technology} key={index}>
              {capitalizeFirstLetter(item?.technology)}
            </option>
          ))}
        </select>
      </div>
      <div className="col-md-3 mb-4">
        <select
          className="form-select"
          aria-label="Filter by course"
          value={selectedCourse}
          onChange={handleCourseChange}
        >
          <option value="">Filter by subject</option>
          {courseList?.map((item, index) => (
            <option value={item?.course} key={index}>
              {capitalizeFirstLetter(item?.course)}
            </option>
          ))}
        </select>
      </div>
      {selectedCourse && (
        <div className="col-md-3 mb-4">
          <select
            className="form-select"
            aria-label="Filter by subtopic"
            value={selectedSubTopic}
            onChange={handleSubTopicChange}
          >
            <option value="">Filter by subtopic</option>
            {subTopicData?.map((item) => (
              <option value={item?.subtopic}>
                {capitalizeFirstLetter(item?.subtopic)}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}
