import * as Yup from "yup";

export const lessonValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Lesson title is required")
    .min(3, "Lesson title must be at least 3 characters long")
    .max(100, "Lesson title must be less than 100 characters"),
    
  content: Yup.string()
    .required("Content is required")
    .min(10, "Content must be at least 10 characters long")
    .max(500, "Content must be less than 500 characters"),
    
  video_url: Yup.string()
    .required("Video URL is required"),
    // .url("Please enter a valid URL"),
    
  duration: Yup.number()
    .required("Duration is required")
    .min(0.1, "Duration must be greater than 0"),
    
  subtopicId: Yup.string()
    .required("Subtopic is required")
    .uuid("Subtopic ID must be a valid UUID"),
    courseId: Yup.string()
    .required("Course is required")
    .uuid("Course ID must be a valid UUID"),
});
