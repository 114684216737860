import React from "react";
import { Link } from "react-router-dom";
import "./ResourceCard.css";
function ResourceCard({ data }) {
  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="dashboard__table table-responsive">
      <table>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Title</th>
            <th>Subject</th>
            <th>Technology/Programming</th>
            <th className="text-center">Download</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={item?.id}>
                 <th>
                <span>{index+1}</span>
                
              </th>
              <th>
                <span>{item?.title}</span>
               
              </th>
              <th>
                <p>{item?.course}</p>
               
              </th>
              <td>
                <p>{item?.technology}</p>
              </td>
              
              <td>
                <div className="dashboard__button__group">
                  <Link
                    className="dashboard__small__btn__2"
                    to={""}
                    onClick={() => handleDownload(item?.download_url)}
                  >
                    <i className="icofont-download"></i>  Download
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

     
    </div>
  );
}

export default ResourceCard;
