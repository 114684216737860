import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, updatePassword } from "../../../redux/slices/auth/authSlice";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ButtonLoader from "../../../shared-components/loader/ButtonLoader";
import toast from "react-hot-toast";
import { ERROR_MESSAGE } from "../../../utils/PropertyResolver";

function UpdatePassword({ setActiveTab }) {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading]= useState(false)
  const dispatch = useDispatch();
  const {  error } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (confirmPassword && password) {
      try {
        setIsLoading(true)
        if(confirmPassword !== password){
            return toast.error(ERROR_MESSAGE.CONFIRM_PASSWORD_NOT_MATCHED)
        }
        // Wait for the dispatch to finish
        await dispatch(updatePassword({ token, password })).unwrap();
        // If successful, navigate to the home page
        navigate("/login");
      } catch (error) {}finally{
        setIsLoading(false)
      }
    }
  };

  const isButtonDisabled = !confirmPassword || !password || isLoading;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div
      className="tab-pane fade active show mt-4 mb-4"
      id="projects__one"
      role="tabpanel"
      aria-labelledby="projects__one"
    >
      <div className="col-xl-8 col-md-8 offset-md-2">
        <div className="loginarea__wraper">
          <div className="login__heading">
            <h5 className="login__title">Update Password</h5>
            {/* {error && <p className="login__error">{error}</p>} */}
          </div>

          <form onSubmit={handleSubmit}>
            <div className="login__form">
              <label className="form__label">Password</label>
              <div
                className="password__input__wrapper"
                style={{ position: "relative" }}
              >
                <input
                  className="common__login__input"
                  type={showPassword ? "text" : "password"} // Toggle between text and password
                  placeholder="Password"
                  value={password}
                  autoFocus
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="password__toggle"
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  <i
                    className={
                      showPassword ? "icofont-eye" : "icofont-eye-blocked"
                    }
                  ></i>{" "}
                  {/* Icon */}
                </span>
              </div>
            </div>

            <div className="login__form">
              <label className="form__label">Confirm Password</label>
              <div
                className="password__input__wrapper"
                style={{ position: "relative" }}
              >
                <input
                  className="common__login__input"
                  type={showConfirmPassword ? "text" : "password"} // Toggle between text and password
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <span
                  className="password__toggle"
                  onClick={toggleConfirmPasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  <i
                    className={
                      showConfirmPassword
                        ? "icofont-eye"
                        : "icofont-eye-blocked"
                    }
                  ></i>{" "}
                  {/* Icon */}
                </span>
              </div>
            </div>

            <div className="login__button">
              <button
                type="submit"
                className="default__button"
                disabled={isButtonDisabled}
                style={{ width: "100%" }}
              >
                {isLoading ? <ButtonLoader /> : "Log In"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdatePassword;
