import React, { useState } from "react";

import UserDashboard from "../../user/UserDashboard";
import UserProfile from "../../user/UserProfile";
import UserSettings from "../../user/UserSettings";
import { Link, useNavigate } from "react-router-dom";
import ComingSoon from "../../../shared-components/comingSoon/ComingSoon";
import CreateCourse from "../../../admin/courses/CreateCourse";
import CreateSublessons from "../../../admin/subLessons/CreateSublessons";
import CreateLessons from "../../../admin/lessons/CreateLessons";
import CreateResource from "../../../admin/resources/CreateResource";
import AssignmentCreate from "../../../admin/assignment/AssignmentCreate";
import { logout } from "../../../redux/slices/auth/authSlice";
import { useDispatch } from "react-redux";
// Import other components as needed
// import Dashboard from "../../../pages/dashboard/Dashboard";
// import Messages from "../../../pages/messages/Messages";
// import Courses from "../../../pages/courses/Courses";
// import Settings from "../../../pages/settings/Settings";

function Sidebar({ userInfo }) {
  const [activeTab, setActiveTab] = useState("dashboard"); // default active tab
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const renderContent = () => {
    switch (activeTab) {
      case "dashboard":
        return <ComingSoon />;
      case "createcourse":
        return <CreateCourse />;
      case "subLesson":
        return <CreateSublessons />;
      case "createLesson":
        return <CreateLessons />;
      case "createAssignment":
        return <AssignmentCreate />;
      case "settings":
        return <UserSettings />;
      case "myquiz":
        return <ComingSoon />;
      case "wishlist":
        return <ComingSoon />;
      case "createResource":
        return <CreateResource />;
      default:
        return <UserProfile />;
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <>
      <div className="dashboard">
        <div className="container-fluid full__width__padding">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-12">
              <div className="dashboard__inner sticky-top">
                <div className="dashboard__nav__title">
                  <h6>
                    Welcome, {userInfo?.first_name} {userInfo?.last_name}
                  </h6>
                </div>
                <div className="dashboard__nav">
                  <ul>
                    <li>
                      <Link
                        className={activeTab === "dashboard" ? "active" : ""}
                        to={""}
                        onClick={() => setActiveTab("dashboard")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={activeTab === "createcourse" ? "active" : ""}
                        to={""}
                        onClick={() => setActiveTab("createcourse")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-user"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                        Create course
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={activeTab === "subLesson" ? "active" : ""}
                        to={""}
                        onClick={() => setActiveTab("subLesson")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-bookmark"
                        >
                          <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                        </svg>
                        Create SubLesson
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={activeTab === "createLesson" ? "active" : ""}
                        to={""}
                        onClick={() => setActiveTab("createLesson")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-bookmark"
                        >
                          <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                        </svg>
                        Create Lesson
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={
                          activeTab === "createAssignment" ? "active" : ""
                        }
                        to={""}
                        onClick={() => setActiveTab("createAssignment")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-bookmark"
                        >
                          <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                        </svg>
                        Create Assignment
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={
                          activeTab === "createResource" ? "active" : ""
                        }
                        to={""}
                        onClick={() => setActiveTab("createResource")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-bookmark"
                        >
                          <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                        </svg>
                        Create Resource
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={activeTab === "myquiz" ? "active" : ""}
                        to={""}
                        onClick={() => setActiveTab("myquiz")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-help-circle"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                          <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                        My Quiz Attempts
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={activeTab === "settings" ? "active" : ""}
                        to={""}
                        onClick={() => setActiveTab("settings")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-settings"
                        >
                          <circle cx="12" cy="12" r="3"></circle>
                          <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                        </svg>
                        Settings
                      </Link>
                    </li>

                    <li>
                      <Link to={""} onClick={handleLogout}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-volume-1"
                        >
                          <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                          <path d="M15.54 8.46a5 5 0 0 1 0 7.07"></path>
                        </svg>
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-12">
              {/* Conditionally render the content based on activeTab */}
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
