import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { socialLinksValidationSchema } from "../../../validationSchema/socialLinksUpdate";
import { useDispatch } from "react-redux";
import { updateUserSocialMedia } from "../../../redux/slices/userProfile/userProfileSlice";

function SocialIconUpdate({ userInfo }) {
  const dispatch = useDispatch()
  const handleSubmit = (values) => {
    console.log("Form values:", values);
    const payload = {
      facebook: values?.facebook,
      twitter: values?.twitter,
      linkedin: values?.linkedin,
      github: values?.github,
      website: values?.website
    }
    dispatch(updateUserSocialMedia(payload))
  };

  return (
    <div
      className="tab-pane active"
      id="projects__three"
      role="tabpanel"
      aria-labelledby="projects__three"
    >
      <div className="row">
        <div className="col-xl-12">
          <Formik
            initialValues={{
              facebook: userInfo?.profile?.facebook || "",
              twitter: userInfo?.profile?.twitter || "",
              linkedin: userInfo?.profile?.linkedin || "",
              website: userInfo?.profile?.website || "",
              github: userInfo?.profile?.github || "",
            }}
            validationSchema={socialLinksValidationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-facebook"
                    >
                      <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                    </svg>
                    Facebook
                  </label>
                  <Field
                    type="text"
                    name="facebook"
                    placeholder="https://facebook.com/"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="facebook"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-twitter"
                    >
                      <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                    </svg>
                    Twitter
                  </label>
                  <Field
                    type="text"
                    name="twitter"
                    placeholder="https://twitter.com/"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="twitter"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-linkedin"
                    >
                      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                      <rect x="2" y="9" width="4" height="12"></rect>
                      <circle cx="4" cy="4" r="2"></circle>
                    </svg>
                    Linkedin
                  </label>
                  <Field
                    type="text"
                    name="linkedin"
                    placeholder="https://linkedin.com/"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="linkedin"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-layout"
                    >
                      <rect
                        x="3"
                        y="3"
                        width="18"
                        height="18"
                        rx="2"
                        ry="2"
                      ></rect>
                      <line x1="3" y1="9" x2="21" y2="9"></line>
                      <line x1="9" y1="21" x2="9" y2="9"></line>
                    </svg>
                    Website
                  </label>
                  <Field
                    type="text"
                    name="website"
                    placeholder="https://website.com/"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="website"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-github"
                    >
                      <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                    </svg>
                    Github
                  </label>
                  <Field
                    type="text"
                    name="github"
                    placeholder="https://github.com/"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="github"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="dashboard__form__button">
                <button className="default__button" type="submit">
                  Update Social Links
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default SocialIconUpdate;
