import * as Yup from "yup";
export const registerUserValidationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),
    profile: Yup.mixed().required("Profile picture is required"),
    acceptTerms: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and privacy policy"
    ),
  });