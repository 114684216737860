import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../../services/axiosRequestHandler";
import { ERROR_MESSAGE } from "../../../utils/PropertyResolver";
import { API_END_POINT } from "../../../utils/ApiEndPoints";
import toast from "react-hot-toast";

// Define the initial state
const courseState = {
  isLoading: false,
  error: null,
  courseData: {
    totalCount: 0,
    data: [],
  },
  courseList: [],
  popularCourse: [],
};

// fetching all the course
export const getCourseList = createAsyncThunk(
  "getCourseList",
  async (params, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.GET_ALL_COURSES, params);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.COURSElIST_NOT_FOUND);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.COURSElIST_NOT_FOUND);
    }
  }
);

export const createCourse = createAsyncThunk(
  "createCourse",
  async (payload, thunkAPI) => {
    try {
      // Prepare headers for multipart/form-data
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      const response = await POST(
        API_END_POINT.CREATE_COURSE,
        payload,
        headers
      );
      console.log(response);
      if (response?.status === 200) {
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.UNABLE_TO_CREATE_COURSE);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.UNABLE_TO_CREATE_COURSE);
    }
  }
);

export const allCourseList = createAsyncThunk(
  "allCourseList",
  async (params, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.GET_ALL_COURSES_TITLE, params);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.COURSElIST_NOT_FOUND);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.COURSElIST_NOT_FOUND);
    }
  }
);

export const getPopularCourse = createAsyncThunk(
  "getPopularCourse",
  async (params, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.GET_POPULAR_COURSE, params);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

const courseSlice = createSlice({
  name: "courseSlice",
  initialState: courseState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getCourseList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCourseList.fulfilled, (state, action) => {
        const { total_count, data } = action.payload || {};
        state.isLoading = false;
        state.courseData = {
          totalCount: total_count,
          data: data,
        };
      })
      .addCase(getCourseList.rejected, (state, action) => {
        state.isLoading = false;
        state.courseData = {
          totalCount: 0,
          data: [],
        };
        state.error = action.payload || ERROR_MESSAGE.FAILED_FETCH_POPULAR_POST;
      })
      .addCase(createCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCourse.fulfilled, (state, action) => {
        const { total_count, data } = action.payload || {};
        state.isLoading = false;
      })
      .addCase(createCourse.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      })
      .addCase(allCourseList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allCourseList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.courseList = action.payload;
      })
      .addCase(allCourseList.rejected, (state, action) => {
        state.isLoading = false;
        state.courseList = [];
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      })
      .addCase(getPopularCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPopularCourse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.popularCourse = action.payload;
      })
      .addCase(getPopularCourse.rejected, (state, action) => {
        state.isLoading = false;
        state.popularCourse = [];
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      });
  },
});

export const {} = courseSlice.actions;

export default courseSlice.reducer;
