import React from "react";
import { Link } from "react-router-dom";

function CoursePagination({ currentPage, totalCount, pageSize, onPageChange }) {
  const totalPages = Math.ceil(totalCount / pageSize);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <div className="main__pagination__wrapper">
      <ul className="main__page__pagination">
        {/* Previous Button - Disable on first page */}
        <li>
          {currentPage === 1 ? (
            <span className="disable">
              <i className="icofont-double-left"></i>
            </span>
          ) : (
            <Link to={""} onClick={() => handlePageClick(currentPage - 1)}>
              <i className="icofont-double-left"></i>
            </Link>
          )}
        </li>

        {/* Page Numbers */}
        {Array.from({ length: totalPages }, (_, index) => (
          <li key={index}>
            <Link
              to={""}
              className={currentPage === index + 1 ? "active" : ""}
              onClick={() => handlePageClick(index + 1)}
            >
              {index + 1}
            </Link>
          </li>
        ))}

        {/* Next Button - Disable on last page */}
        <li>
          {currentPage === totalPages ? (
            <span className="disable">
              <i className="icofont-double-right"></i>
            </span>
          ) : (
            <Link to={""} onClick={() => handlePageClick(currentPage + 1)}>
              <i className="icofont-double-right"></i>
            </Link>
          )}
        </li>
      </ul>
    </div>
  );
}

export default CoursePagination;
