import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { POST } from "../../../services/axiosRequestHandler";
import { CONSTANT_NAME, ERROR_MESSAGE } from "../../../utils/PropertyResolver";
import { API_END_POINT } from "../../../utils/ApiEndPoints";
import toast from "react-hot-toast";
import { getUserProfile } from "../userProfile/userProfileSlice";

// Define the initial state
const initialState = {
  isLoading: false,
  error: null,
  userData: {},
  isAuthenticated: false,
};

// Register user
export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (userData, thunkAPI) => {
    try {
      // Prepare headers for multipart/form-data
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      const response = await POST(
        API_END_POINT.REGISTER_USER,
        userData,
        headers
      );
      if (response?.status === 200) {
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(
          response.response?.data?.message || ERROR_MESSAGE.REGISTER_FAILED
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.REGISTER_FAILED);
    }
  }
);

// Login user
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (credentials, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.LOGIN_USER, credentials);
      if (response?.status === 200) {
        localStorage.setItem(
          CONSTANT_NAME.TOKEN,
          response.response?.data?.data?.token
        );
        await thunkAPI.dispatch(getUserProfile());
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(
          response.response?.data?.message || ERROR_MESSAGE.LOGIN_FAILED
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.LOGIN_FAILED);
    }
  }
);

export const sendEmailForForgotPassword = createAsyncThunk(
  "auth/sendEmailForForgotPassword",
  async (userData, thunkAPI) => {
    try {
      const response = await POST(
        API_END_POINT.LINK_SEND_TO_FORGOT_PASSWORD,
        userData
      );
      if (response?.status === 200) {
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(
          response.response?.data?.message || ERROR_MESSAGE.SOMETHING_WENT_WRONG
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async (userData, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.RESET_PASSWORD, userData);
      if (response?.status === 200) {
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(
          response.response?.data?.message || ERROR_MESSAGE.SOMETHING_WENT_WRONG
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      localStorage.removeItem("token");
      state.userData = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userData = action.payload;
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.REGISTER_FAILED;
      })
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userData = action.payload;
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.LOGIN_FAILED;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
