import axios from "axios";
import { BASE_URL } from "../utils/ApiEndPoints";
import { logout } from "../redux/slices/auth/authSlice";

const Axios = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor
Axios.interceptors.request.use(
  function (config) {
    // Modify the request config here (add headers, authentication tokens)
    const accessToken = localStorage.getItem("token");

    // If token is present add it to request's Authorization Header
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error) {
    // Handle request errors here
    return Promise.reject(error);
  }
);
// End of Request interceptor

// Response interceptor
Axios.interceptors.response.use(
  function (response) {
    // Modify the response data here
    return response;
  },
  async function (error) {
    // Handle response errors here
    if(error.response && error?.response?.data && error?.response?.data?.message === "Invalid token"){
     // Dynamically import the store and dispatch action to avoid circular dependency
     const { store } = await import('../redux/store');
     store.dispatch(logout()) 
    }
    return Promise.reject(error);
  }
);
// End of Response interceptor

export default Axios;
