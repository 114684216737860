import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { profileUpdateValidationSchema } from "../../../validationSchema/profileUpdate";
import { updateUserProfile } from "../../../redux/slices/userProfile/userProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../shared-components/loader/ButtonLoader";
import Label from "../../../shared-components/label/Lable";

export default function ProfileUpdateForm({ userInfo }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.userProfile);

  const handleSubmit = (values, { resetForm }) => {
    try {
      const payload = {
        first_name: values?.firstName,
        last_name: values?.lastName,
        phone_number: values?.phoneNumber,
        skill: values?.skill,
        bio: values?.bio,
        user_name: values?.userName,
      };
      dispatch(updateUserProfile(payload));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="tab-pane active"
      id="projects__one"
      role="tabpanel"
      aria-labelledby="projects__one"
    >
      <div className="col-xl-12">
        <Formik
          initialValues={{
            firstName: userInfo?.first_name || "",
            lastName: userInfo?.last_name || "",
            userName: userInfo?.user_name || "",
            phoneNumber: userInfo?.profile?.phone_number || "",
            skill: userInfo?.profile?.skill || "",
            email: userInfo?.email || "",
            bio: userInfo?.profile?.bio || "",
          }}
          validationSchema={profileUpdateValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="row">
              <div className="col-xl-6">
                <div className="dashboard__form__wraper">
                  <div className="dashboard__form__input">
                    <Label label="First Name" htmlFor="firstName" isRequired />
                    <Field
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="dashboard__form__wraper">
                  <div className="dashboard__form__input">
                    <Label label="Last Name" htmlFor="lastName" isRequired />
                    <Field
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="dashboard__form__wraper">
                  <div className="dashboard__form__input">
                    <Label label="User Name" htmlFor="userName" isRequired />
                    <Field
                      type="text"
                      name="userName"
                      placeholder="User name"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="userName"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="dashboard__form__wraper">
                  <div className="dashboard__form__input">
                    <Label label="Phone Number" htmlFor="phoneNumber" isRequired />
                    <Field
                      type="text"
                      name="phoneNumber"
                      placeholder="Phone number"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="dashboard__form__wraper">
                  <div className="dashboard__form__input">
                    <Label label="Skill/Occupation" htmlFor="skill" isRequired />
                    <Field
                      type="text"
                      name="skill"
                      placeholder="Enter skill (Full stack developer)"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="skill"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="dashboard__form__wraper">
                  <div className="dashboard__form__input">
                    <Label label="Email" htmlFor="email" isRequired={false} />
                    <Field
                      type="text"
                      name="email"
                      placeholder="John"
                      className="form-control"
                      disabled={true}
                    />
                   
                  </div>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="dashboard__form__wraper">
                  <div className="dashboard__form__input">
                    <Label label="Bio" htmlFor="bio" isRequired />
                    <Field
                      as="textarea"
                      name="bio"
                      placeholder="Your bio..."
                      className="form-control"
                      rows="4"
                    />
                    <ErrorMessage
                      name="bio"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="dashboard__form__button">
                  <button
                    className="default__button"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? <ButtonLoader /> : "Update Info"}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
