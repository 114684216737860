import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../redux/slices/auth/authSlice";
import { Link, useNavigate } from "react-router-dom";
import ButtonLoader from "../../../shared-components/loader/ButtonLoader";

function Login({setActiveTab}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); 
  const [rememberPassword, setRememberPassword] = useState(false)
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email && password) {
      try {
        // Wait for the dispatch to finish
        await dispatch(loginUser({ email, password, rememberPassword })).unwrap();
        // If successful, navigate to the home page
        navigate("/");
      } catch (error) {
       
      }
    }
  };
  

  const isButtonDisabled = !email || !password || isLoading;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className="tab-pane fade active show"
      id="projects__one"
      role="tabpanel"
      aria-labelledby="projects__one"
    >
      <div className="col-xl-8 col-md-8 offset-md-2">
        <div className="loginarea__wraper">
          <div className="login__heading">
            <h5 className="login__title">Login</h5>
            {/* {error && <p className="login__error">{error}</p>} */}
          </div>

          <form onSubmit={handleSubmit}>
            <div className="login__form">
              <label className="form__label">Username or email</label>
              <input
                className="common__login__input"
                type="text"
                placeholder="Your username or email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
            </div>
            <div className="login__form">
              <label className="form__label">Password</label>
              <div
                className="password__input__wrapper"
                style={{ position: "relative" }}
              >
                <input
                  className="common__login__input"
                  type={showPassword ? "text" : "password"} // Toggle between text and password
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="password__toggle"
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  <i
                    className={
                      showPassword ? "icofont-eye" : "icofont-eye-blocked"
                    }
                  ></i>{" "}
                  {/* Icon */}
                  
                </span>
              </div>
            </div>
            <div className="login__form d-flex justify-content-between flex-wrap gap-2">
              <div className="form__check">
                <input id="forgot" type="checkbox" value={rememberPassword} onChange={(e)=>setRememberPassword(e.target.value)}/>
                <label htmlFor="forgot"> Remember me</label>
              </div>
              <div className="text-end login__form__link">
                <Link to={""} onClick={()=>setActiveTab("forgotPassword")} className="signup-link">Forgot your password?</Link>
              </div>
            </div>
            <div className="login__button">
              <button
                type="submit"
                className="default__button"
                disabled={isButtonDisabled}
                style={{ width: "100%" }}
              >
                {isLoading ? <ButtonLoader /> : "Log In"}
              </button>
            </div>
          </form>

          {/* Uncomment if you want to include social login options */}
          {/* <div className="login__social__option">
            <p>or Log-in with</p>
            <ul className="login__social__btn">
              <li><a className="default__button login__button__1" href="#"><i className="icofont-facebook"></i> Facebook</a></li>
              <li><a className="default__button" href="#"><i className="icofont-google-plus"></i> Google</a></li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Login;
