import React from "react";
import BigBlogCard from "../../../shared-components/blog/BigBlogCard";
import BlogSidebar from "./BlogSidebar";

function AllBlog() {
  return (
    <>
      <div class="blogarea__2 sp_top_100 sp_bottom_100">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
              <BigBlogCard />
              <div class="main__pagination__wrapper" >
                <ul class="main__page__pagination">
                  <li>
                    <a class="disable" href="#">
                      <i class="icofont-double-left"></i>
                    </a>
                  </li>
                  <li>
                    <a class="active" href="#">
                      1
                    </a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#">3</a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="icofont-double-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <BlogSidebar />
          </div>
        </div>
      </div>
    </>
  );
}

export default AllBlog;
