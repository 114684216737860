import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useParams } from "react-router-dom";
import HorizontalCourseCard from "../../shared-components/courseCard/HorizontalCourseCard";
import CourseCard from "../../shared-components/courseCard/CourseCard";
import { getCourseList } from "../../redux/slices/coureses/courseSlice";
import MainLoader from "../../shared-components/loader/MainLoader";
import { CONSTANT_NAME } from "../../utils/PropertyResolver";
import CoursePagination from "../all-course/component/CoursePagination";

function CourseByFilter() {
  const { filter } = useParams();
  const dispatch = useDispatch();
  const { isLoading, courseData } = useSelector((state) => state.course);

  const [currentPage, setCurrentPage] = useState(1);
  const [componentType, setComponentType] = useState(CONSTANT_NAME.VERTICAL);
  const pageSize = 8;

  useEffect(() => {
    const params = `?page=${currentPage}&pageSize=${pageSize}&filter=${filter}`;
    dispatch(getCourseList(params));
  }, [dispatch, currentPage, filter]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {isLoading && <MainLoader />}
      <div className="coursearea sp_top_100 sp_bottom_100">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="course__text__wraper">
                <div className="course__text">
                  <p>
                    Showing {(currentPage - 1) * pageSize + 1}–
                    {Math.min(currentPage * pageSize, courseData.totalCount)} of{" "}
                    {courseData.totalCount} Results
                  </p>
                </div>
                <div className="course__icon">
                  <ul
                    className="nav property__team__tap"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <Link
                        to={""}
                        onClick={() =>
                          setComponentType(CONSTANT_NAME.HORIZONTAL)
                        }
                        className={
                          componentType === CONSTANT_NAME.HORIZONTAL
                            ? "single__tab__link active"
                            : ""
                        }
                        data-bs-toggle="tab"
                        data-bs-target="#projects__one"
                      >
                        <i className="icofont-layout"></i>
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link
                        to={""}
                        onClick={() => setComponentType(CONSTANT_NAME.VERTICAL)}
                        className={
                          componentType === CONSTANT_NAME.VERTICAL
                            ? "single__tab__link active"
                            : ""
                        }
                        data-bs-toggle="tab"
                        data-bs-target="#projects__two"
                      >
                        <i className="icofont-listine-dots"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                {componentType !== CONSTANT_NAME.HORIZONTAL
                  ? courseData?.data?.map((item, index) => (
                      <CourseCard data={item} key={item?.id || index} />
                    ))
                  : courseData?.data?.map((item, index) => (
                      <HorizontalCourseCard
                        data={item}
                        key={item?.id || index}
                      />
                    ))}
              </div>
            </div>

            <CoursePagination
              currentPage={currentPage}
              totalCount={courseData.totalCount}
              pageSize={pageSize}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseByFilter;
