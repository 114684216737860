import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";

import ButtonLoader from "../../../shared-components/loader/ButtonLoader";
import MainLoader from "../../../shared-components/loader/MainLoader";
import {  getAllSubTopicByCourseId, getSubTopicList } from "../../../redux/slices/subTopics/subTopicSlice";
import { lessonValidationSchema } from "../../../validationSchema/lessonCreate";
import { createLesson } from "../../../redux/slices/lessons/lessonSlice";
import { allCourseList } from "../../../redux/slices/coureses/courseSlice";


function LessonForm() {
  const dispatch = useDispatch();
  const [selectedCourseId, setSelectedCourseId] = useState("");

  // const { isLoading } = useSelector((state) => state.lessons);
  const { subTopicByCourseId: subTopics, isLoading: subTopicsLoading } =
    useSelector((state) => state.subTopic);
  const { courseList: courses, isLoading: coursesLoading } = useSelector(
    (state) => state.course
  );
  const isLoading =subTopicsLoading || coursesLoading
  useEffect(() => {
    dispatch(allCourseList());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCourseId) {
      dispatch(getAllSubTopicByCourseId(`?id=${selectedCourseId}`));
    }
  }, [dispatch, selectedCourseId]); // Ensure selectedCourseId is in dependency array

  const handleCourseChange = (e, setFieldValue) => {
    const selectedCourse = e.target.value;
    setSelectedCourseId(selectedCourse); // Update state
    setFieldValue("courseId", selectedCourse); // Update Formik field value
  };

  const handleSubmit = async (values, {resetForm}) => {
    console.log(values);
    const payload = {
      title: values.title,
      content: values.content,
      video_url: values.video_url,
      duration: values.duration,
      subtopic_id: values.subtopicId,
    };

    try {
      // Wait for the dispatch to finish
      await dispatch(createLesson(payload)).unwrap();
      resetForm()
    } catch (error) {
      // Handle error
    }
  };

  return (
    <>
      {isLoading && <MainLoader />}
      <div className=" " id="lesson__form" role="tabpanel">
        <div className="col-xl-12">
          <div className="loginarea__wraper dashboard__content__wraper">
            <div className="login__heading">
              <h5 className="login__title">Create Lesson</h5>
            </div>

            <Formik
              initialValues={{
                title: "",
                content: "",
                video_url: "",
                duration: 0,
                subtopicId: "",
                courseId:""
              }}
              validationSchema={lessonValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, setFieldValue  }) => (
                <Form>
                  <div className="row">
                  <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Course<span className="error">*</span>
                        </label>
                        <Field
                          as="select"
                          className="common__login__input"
                          name="courseId"
                          onChange={(e) => handleCourseChange(e, setFieldValue)}
                        >
                          <option value="">Select Course</option>
                          {courses?.map((course) => (
                            <option
                              key={course?.course_id}
                              value={course?.course_id}
                            >
                              {course?.title}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="courseId"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Subtopic<span className="error">*</span>
                        </label>
                        <Field
                          as="select"
                          className="common__login__input"
                          name="subtopicId"
                          disabled={!selectedCourseId}
                        >
                          <option value="">Select Subtopic</option>
                          {subTopics.map((subTopic) => (
                            <option
                              key={subTopic?.subtopic_id}
                              value={subTopic?.subtopic_id}
                            >
                              {subTopic?.title}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="subtopicId"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Lesson Title<span className="error">*</span>
                        </label>
                        <Field
                          className="common__login__input"
                          name="title"
                          placeholder="Lesson Title"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Duration in Minutes<span className="error">*</span>
                        </label>
                        <Field
                          className="common__login__input"
                          name="duration"
                          placeholder="Lesson Duration"
                          type="number"
                          step="0.1"
                        />
                        <ErrorMessage
                          name="duration"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Video URL<span className="error">*</span>
                        </label>
                        <Field
                          className="common__login__input"
                          name="video_url"
                          placeholder="Video URL"
                        />
                        <ErrorMessage
                          name="video_url"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-12">
                      <div className="login__form">
                        <label className="form__label">
                          Content<span className="error">*</span>
                        </label>
                        <Field
                          as="textarea"
                          className="common__login__input"
                          name="content"
                          placeholder="Lesson Content"
                          rows="5"
                        />
                        <ErrorMessage
                          name="content"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="login__button">
                    <button
                      className="default__button"
                      type="submit"
                      style={{ width: "100%" }}
                      disabled={isLoading || isSubmitting}
                    >
                      {isLoading ? <ButtonLoader /> : "Submit Lesson"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default LessonForm;
