import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./css/bootstrap.min.css";
import "./css/animate.min.css";
import "./css/aos.min.css";
import "./css/magnific-popup.css";
import "./css/icofont.min.css";
import "./css/slick.css";
import "./css/swiper-bundle.min.css";
import "./css/style.css";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={"Loading..."} persistor={persistor}>
      <Toaster />
      <App />
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);
