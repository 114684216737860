import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ERROR_MESSAGE, urlMappings } from "../../../utils/PropertyResolver";
import "./DashboardHeader.css";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileImage } from "../../../redux/slices/userProfile/userProfileSlice";
import ButtonLoader from "../../../shared-components/loader/ButtonLoader";

function DashboardHeader({ userInfo }) {
  const [hover, setHover] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const dispatch = useDispatch();
  const { profilePicLoading } = useSelector((state) => state.userProfile);

  // Handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];

      // Check if the file type is valid
      if (!allowedTypes.includes(file.type)) {
        toast.error(ERROR_MESSAGE.ALLOW_IMG);
        return;
      }
      setProfileImage(file);
      const formData = new FormData();
      formData.append("profile", file);
      dispatch(updateProfileImage(formData));
    }
  };

  return (
    <>
      <div className="container-fluid full__width__padding">
        <div className="row">
          <div className="col-xl-12">
            <div className="dashboardarea__wraper">
              <div className="dashboardarea__img">
                <div className="dashboardarea__inner admin__dashboard__inner">
                  <div className="dashboardarea__left">
                    <div
                      className="dashboardarea__left__img"
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                      style={{ position: "relative" }}
                    >
                      {profilePicLoading ? (
                       <div className="image__loader__wrapper">
                       <div className="loader"></div> 
                     </div>
                      ) : (
                        <img
                          loading="lazy"
                          src={
                            profileImage
                              ? URL.createObjectURL(profileImage)
                              : userInfo?.profile_url
                          }
                          alt=""
                        />
                      )}
                      {hover && !profilePicLoading && (
                        <>
                          <label
                            htmlFor="file-input"
                            className="upload__icon__label upload__icon__overlay"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-upload"
                            >
                              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                              <polyline points="17 8 12 3 7 8" />
                              <line x1="12" y1="3" x2="12" y2="15" />
                            </svg>
                          </label>
                          <input
                            id="file-input"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                        </>
                      )}
                    </div>
                    <div className="dashboardarea__left__content">
                      <h5>Hello</h5>
                      <h4>{userInfo?.first_name}</h4>
                    </div>
                  </div>
                  <div className="dashboardarea__right">
                    <div className="dashboardarea__right__button">
                      <Link
                        to={urlMappings.YOUTUBE_URL}
                        className="default__button"
                        target="_blank"
                      >
                        Subscribe Now
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-arrow-right"
                        >
                          <line x1="5" y1="12" x2="19" y2="12"></line>
                          <polyline points="12 5 19 12 12 19"></polyline>
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardHeader;
