import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  sendEmailForForgotPassword,
} from "../../../redux/slices/auth/authSlice";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../../shared-components/loader/ButtonLoader";

function ForgotPassword({ setActiveTab }) {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.auth);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        setIsLoading(true);
        // Wait for the dispatch to finish
        await dispatch(sendEmailForForgotPassword({ email })).unwrap();
        setActiveTab("login");
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const isButtonDisabled = !email || isLoading;
  return (
    <div
      className="tab-pane fade active show"
      id="projects__one"
      role="tabpanel"
      aria-labelledby="projects__one"
    >
      <div className="col-xl-8 col-md-8 offset-md-2">
        <div className="loginarea__wraper">
          <div className="login__heading">
            <h5 className="login__title">Forgot Password</h5>
            {/* {error && <p className="login__error">{error}</p>} */}
          </div>

          <form onSubmit={handleSubmit}>
            <div className="login__form">
              <label className="form__label">Email</label>
              <input
                className="common__login__input"
                type="text"
                placeholder="Your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
            </div>

            <div className="login__button">
              <button
                type="submit"
                className="default__button"
                disabled={isButtonDisabled}
                style={{ width: "100%" }}
              >
                {isLoading ? <ButtonLoader /> : "Update Password"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
