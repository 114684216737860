import React, { useEffect } from "react";
import DashboardHeader from "./component/DashboardHeader";
import Sidebar from "./component/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import AdminSidebar from "./component/AdminSidebar";
import { getUserProfile } from "../../redux/slices/userProfile/userProfileSlice";
import MainLoader from "../../shared-components/loader/MainLoader";

function Dashboard() {
  const { userData } = useSelector((state) => state.auth);
  const { userInfo, isLoading } = useSelector((state) => state.userProfile);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getUserProfile());
  // }, [dispatch]);
  return (
    <>
      {isLoading && <MainLoader />}
      <div className="dashboardarea sp_bottom_100">
        <DashboardHeader userInfo={userInfo} />
        {userData?.user_info?.user_role === 2 && (
          <>
            <Sidebar userInfo={userInfo} />
          </>
        )}
        {userData?.user_info?.user_role === 1 && (
          <>
            <AdminSidebar userInfo={userData?.user_info} />
          </>
        )}
      </div>
    </>
  );
}

export default Dashboard;
