import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../redux/slices/auth/authSlice"; // Update import if using different action
import ButtonLoader from "../../../shared-components/loader/ButtonLoader";
import { courseValidationSchema } from "../../../validationSchema/courseCreate";
import { createCourse } from "../../../redux/slices/coureses/courseSlice";
import MainLoader from "../../../shared-components/loader/MainLoader";
import { getCategoryList } from "../../../redux/slices/courseCategory/categorySlice";

const skillLevels = [
  { id: 1, name: "Beginner" },
  { id: 2, name: "Advanced" },
];
const instructors = [{ id: 1, name: "Vivek Kumar" }];

function CourseForm({ setActiveTab }) {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.course);
  const { categoriesData: categories } = useSelector((state) => state.category);

  const categoryListIsLoading = useSelector(
    (state) => state.category.isLoading
  );

  useEffect(() => {
    dispatch(getCategoryList());
  }, []);

  const gettingIsLoading = categoryListIsLoading;
  const handleSubmit = async (values,{resetForm}) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("price", values.price);
    formData.append("previous_price", values.previousPrice);
    formData.append("thumbnail", values.thumbnailUrl);
    formData.append("category_id", values.categoryId);
    formData.append("skill_level_id", values.skillLevelId);
    formData.append("instructor_id", values.instructorId);
    try {
      // Wait for the dispatch to finish
      await dispatch(createCourse(formData)).unwrap();
      // If successful, you might want to handle navigation or state updates
      setActiveTab("someTab"); 
      resetForm()
    } catch (error) {
      // Handle error
    }
  };

  return (
    <>
      {gettingIsLoading && <MainLoader />}
      <div
        className=" "
        id="projects__two"
        role="tabpanel"
        aria-labelledby="projects__two"
      >
        <div className="col-xl-12 ">
          <div className="loginarea__wraper dashboard__content__wraper">
            <div className="login__heading">
              <h5 className="login__title">Create Course</h5>
            </div>

            <Formik
              initialValues={{
                title: "",
                description: "",
                price: "",
                previousPrice: "",
                thumbnailUrl: "",
                categoryId: "",
                skillLevelId: "",
                instructorId: "",
                profile: null,
              }}
              validationSchema={courseValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Course Title<span className="error">*</span>
                        </label>
                        <Field
                          className="common__login__input"
                          name="title"
                          placeholder="Course Title"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Description<span className="error">*</span>
                        </label>
                        <Field
                          className="common__login__input"
                          name="description"
                          placeholder="Course Description"
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Price<span className="error">*</span>
                        </label>
                        <Field
                          className="common__login__input"
                          type="number"
                          name="price"
                          placeholder="Price"
                        />
                        <ErrorMessage
                          name="price"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">Previous Price</label>
                        <Field
                          className="common__login__input"
                          type="number"
                          name="previousPrice"
                          placeholder="Previous Price"
                        />
                        <ErrorMessage
                          name="previousPrice"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Thumbnail URL<span className="error">*</span>
                        </label>
                        <div
                          className="common__login__input"
                          id="fileUpload"
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          } // trigger click on file input
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <i
                            className="icofont-cloud-upload"
                            style={{ fontSize: "24px", marginRight: "8px" }}
                          ></i>
                          <span>Click to upload</span>
                        </div>
                        <input
                          id="fileInput"
                          type="file"
                          name="thumbnailUrl"
                          hidden
                          onChange={(event) => {
                            const file = event.currentTarget.files[0];
                            setFieldValue("thumbnailUrl", file); 
                          }}
                        />

                        {values?.thumbnailUrl && (
                          <img
                            src={URL.createObjectURL(values.thumbnailUrl)}
                            alt="Thumbnail Preview"
                            width="100"
                          />
                        )}

                        <ErrorMessage
                          name="thumbnailUrl"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Category<span className="error">*</span>
                        </label>
                        <Field
                          as="select"
                          className="common__login__input"
                          name="categoryId"
                        >
                          <option value="">Select Category</option>
                          {categories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="categoryId"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Skill Level<span className="error">*</span>
                        </label>
                        <Field
                          as="select"
                          className="common__login__input"
                          name="skillLevelId"
                        >
                          <option value="">Select Skill Level</option>
                          {skillLevels.map((level) => (
                            <option key={level.id} value={level.id}>
                              {level.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="skillLevelId"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Instructor<span className="error">*</span>
                        </label>
                        <Field
                          as="select"
                          className="common__login__input"
                          name="instructorId"
                        >
                          <option value="">Select Instructor</option>
                          {instructors.map((instructor) => (
                            <option key={instructor.id} value={instructor.id}>
                              {instructor.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="instructorId"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="login__button">
                    <button
                      className="default__button"
                      type="submit"
                      style={{ width: "100%" }}
                      disabled={isLoading}
                    >
                      {isLoading ? <ButtonLoader /> : "Submit Course"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseForm;
