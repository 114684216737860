import React from 'react';

const Label = ({ label, htmlFor, isRequired }) => {
  return (
    <label htmlFor={htmlFor}>
      {label}
      {isRequired && <span className="error"> *</span>}
    </label>
  );
};

export default Label;
