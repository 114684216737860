import React, { useState } from "react";
import ProfileUpdateForm from "./component/ProfileUpdateForm";
import PasswordUpdateForm from "./component/PasswordUpdateForm";
import SocialIconUpdate from "./component/SocialIconUpdate";
import { useSelector } from "react-redux";

function UserSettings() {
  // State to track the active tab
  const [activeTab, setActiveTab] = useState("profile");
  const { userInfo, isLoading } = useSelector((state) => state.userProfile);

  // Function to handle tab switching
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    
        <div className="dashboard__content__wraper">
          <div className="dashboard__section__title">
            <h4>My Profile</h4>
          </div>
          <div className="row">
            <div className="col-xl-12 aos-init aos-animate" data-aos="fade-up">
              <ul
                className="nav about__button__wrap dashboard__button__wrap"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={`single__tab__link ${activeTab === "profile" ? "active" : ""}`}
                    onClick={() => handleTabChange("profile")}
                    type="button"
                    aria-selected={activeTab === "profile"}
                    role="tab"
                  >
                    Profile
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`single__tab__link ${activeTab === "password" ? "active" : ""}`}
                    onClick={() => handleTabChange("password")}
                    type="button"
                    aria-selected={activeTab === "password"}
                    role="tab"
                  >
                    Password
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`single__tab__link ${activeTab === "social" ? "active" : ""}`}
                    onClick={() => handleTabChange("social")}
                    type="button"
                    aria-selected={activeTab === "social"}
                    role="tab"
                  >
                    Social Icon
                  </button>
                </li>
              </ul>
            </div>

            <div
              className="tab-content tab__content__wrapper aos-init aos-animate"
              id="myTabContent"
            >
              {activeTab === "profile" && <ProfileUpdateForm userInfo={userInfo}/>}
              {activeTab === "password" && <PasswordUpdateForm userInfo={userInfo}/>}
              {activeTab === "social" && <SocialIconUpdate userInfo={userInfo}/>}
            </div>
          </div>
        </div>
     
  );
}

export default UserSettings;
