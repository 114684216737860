import React, { useEffect } from 'react'

export default function GoogleAd({client, slot, format, responsive}) {
    useEffect(()=>{
        // ensure adsbygoogle script runs after the component renders
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    },[])
  return (
    <>
      <div className="google-ad">
        <ins
            className='adsbygoogle'
            style={{display:'block'}}
            data-ad-client={client} // ad client ID
            data-ad-slot={slot} // ad slot id
            data-ad-format={format || "auto"}
            data-full-width-responsive={responsive || "true"}
        />
      </div>
    </>
  )
}
