import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST, PUT } from "../../../services/axiosRequestHandler";
import { ERROR_MESSAGE } from "../../../utils/PropertyResolver";
import { API_END_POINT } from "../../../utils/ApiEndPoints";
import toast from "react-hot-toast";

// Define the initial state
const userProfileState = {
  isLoading: false,
  profilePicLoading: false,
  error: null,
  userInfo: {},
};

export const getUserProfile = createAsyncThunk(
  "getUserProfile",
  async (params, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.GET_USER_PROFILE, params);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  "updateUserPassword",
  async (payload, thunkAPI) => {
    try {
      const response = await PUT(API_END_POINT.UPDATE_PASSWORD, payload);
      if (response?.status === 200) {
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "updateUserProfile",
  async (payload, thunkAPI) => {
    try {
      const response = await PUT(API_END_POINT.UPDATE_USER_PROFILE, payload);
      if (response?.status === 200) {
        thunkAPI.dispatch(getUserProfile())
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

export const updateUserSocialMedia= createAsyncThunk(
  "updateUserSocialMedia",
  async (payload, thunkAPI) => {
    try {
      const response = await PUT(API_END_POINT.UPDATE_USER_SOCIAL_MEDIA, payload);
      if (response?.status === 200) {
        thunkAPI.dispatch(getUserProfile())
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

export const updateProfileImage = createAsyncThunk(
  "updateProfileImage",
  async (payload, thunkAPI) => {
    try {
      // Prepare headers for multipart/form-data
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      const response = await POST(API_END_POINT.PROFILE_IMAGE_UPDATE, payload, headers);
      if (response?.status === 200) {
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(
          response.response?.data?.message || ERROR_MESSAGE.SOMETHING_WENT_WRONG
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

const userProfileSlice = createSlice({
  name: "userProfileSlice",
  initialState: userProfileState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userInfo = action.payload;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.userInfo = action.payload;
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      })
      .addCase(updateUserPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserPassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      })
      .addCase(updateUserSocialMedia.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserSocialMedia.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateUserSocialMedia.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      }).addCase(updateProfileImage.pending, (state) => {
        state.profilePicLoading = true;
      })
      .addCase(updateProfileImage.fulfilled, (state, action) => {
        state.profilePicLoading = false;
      })
      .addCase(updateProfileImage.rejected, (state, action) => {
        state.profilePicLoading = false;
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      });
  },
});

export const {} = userProfileSlice.actions;

export default userProfileSlice.reducer;
