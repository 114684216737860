import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { passwordUpdateValidationSchema } from "../../../validationSchema/passwordUpdate";
import { useDispatch, useSelector } from "react-redux";
import { updateUserPassword } from "../../../redux/slices/userProfile/userProfileSlice";
import ButtonLoader from "../../../shared-components/loader/ButtonLoader";
import Label from "../../../shared-components/label/Lable";

export default function PasswordUpdateForm() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.userProfile);

  // State to manage password visibility
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const payload = {
        current_password: values?.currentPassword,
        new_password: values?.newPassword,
        confirm_password: values?.confirmPassword,
      };
      await dispatch(updateUserPassword(payload))
        .unwrap()
        .then(() => {
          resetForm();
        });
    } catch (error) {}
  };

  // Toggle password visibility
  const togglePasswordVisibility = (setShowPassword) => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div
      className="tab-pane active"
      id="projects__two"
      role="tabpanel"
      aria-labelledby="projects__two"
    >
      <div className="row">
        <div className="col-xl-12">
          <Formik
            initialValues={{
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={passwordUpdateValidationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <Label label="Current Password" htmlFor="currentPassword" isRequired />
                  <div className="password__input__wrapper" style={{ position: "relative" }}>
                    <Field
                      type={showCurrentPassword ? "text" : "password"}
                      name="currentPassword"
                      placeholder="Current password"
                      className="form-control"
                    />
                    <span
                      className="password__toggle"
                      onClick={() => togglePasswordVisibility(setShowCurrentPassword)}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      <i className={showCurrentPassword ? "icofont-eye" : "icofont-eye-blocked"}></i>
                    </span>
                  </div>
                  <ErrorMessage
                    name="currentPassword"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <Label label="New Password" htmlFor="newPassword" isRequired />
                  <div className="password__input__wrapper" style={{ position: "relative" }}>
                    <Field
                      type={showNewPassword ? "text" : "password"}
                      name="newPassword"
                      placeholder="New Password"
                      className="form-control"
                    />
                    <span
                      className="password__toggle"
                      onClick={() => togglePasswordVisibility(setShowNewPassword)}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      <i className={showNewPassword ? "icofont-eye" : "icofont-eye-blocked"}></i>
                    </span>
                  </div>
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <Label label="Re-Type New Password" htmlFor="confirmPassword" isRequired />
                  <div className="password__input__wrapper" style={{ position: "relative" }}>
                    <Field
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Re-Type New Password"
                      className="form-control"
                    />
                    <span
                      className="password__toggle"
                      onClick={() => togglePasswordVisibility(setShowConfirmPassword)}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      <i className={showConfirmPassword ? "icofont-eye" : "icofont-eye-blocked"}></i>
                    </span>
                  </div>
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="dashboard__form__button">
                <button
                  className="default__button"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <ButtonLoader /> : "Update Password"}
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}
