import React from 'react'

function BigBlogCard() {
  return (
    <>
      <div class="blog__content__wraper__2" >
                            <div class="blogarae__img__2">
                                <img loading="lazy"  src="img/blog/blog_6.png" alt="blog"/>
                                <div class="blogarea__date__2">
                                    <span>24</span>
                                    <span class="blogarea__month">Feb</span>
                                </div>
                            </div>
                            <div class="blogarea__text__wraper__2">
                                <div class="blogarea__heading__2">
                                    <h3><a href="blog-details.html">Delivering What Consumers Really Value?</a></h3>
                                </div>
                                <div class="blogarea__list__2">
                                    <ul>
                                        <li>
                                            <a href="blog-details.html">
                                                <i class="icofont-business-man-alt-2"></i> Mirnsdo.H
                                            </a>
                                        </li>
                                        <li>
                                            <a href="blog-details.html">
                                                <i class="icofont-speech-comments"></i> 0 Comments
                                            </a>
                                        </li>

                                        <li>
                                            <a href="blog-details.html">
                                                <i class="icofont-eraser-alt"></i> Association
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                                <div class="blogarea__paragraph">
                                    <p>These cases are perfectly simple and easy to distinguish. In a free hour, when our power of On the other hand, organizations have the need for integrating in IT departments</p>
                                </div>
                                <div class="blogarea__button__2">
                                    <a href="blog-details.html">READ MORE
                                <i class="icofont-double-right"></i>
                            </a>
                                    <a href="#">
                                        <div class="blogarea__icon__2">
                                            <i class="icofont-share"></i>
                                            <i class="icofont-heart"></i>
                                        </div>
                                    </a>
                                </div>
                            </div>


                        </div>
    </>
  )
}

export default BigBlogCard
