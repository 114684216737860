import React from 'react'
import AssignmentForm from './component/AssignmentForm'

export default function AssignmentCreate() {
  return (
    <>
      <AssignmentForm />
    </>
  )
}
