import React from 'react'

function BlogSidebar() {
  return (
    <>
       <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <div class="blogsidebar__content__wraper__2" >
                            <div class="blogsidebar__content__inner__2">
                                <div class="blogsidebar__img__2">
                                    <img loading="lazy"  src="img/blog/blog_10.png" alt="blog"/>
                                </div>
                                <div class="blogsidebar__name__2">
                                    <h5>
                                        <a href="#"> Rosalina D. Willaim</a>

                                    </h5>
                                    <p>Blogger/Photographer</p>
                                </div>
                                <div class="blog__sidebar__text__2">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.Veritatis distinctio suscipit reprehenderit atque</p>
                                </div>
                                <div class="blogsidbar__icon__2">
                                    <ul>
                                        <li>
                                            <a href="#"><i class="icofont-facebook"></i></a>
                                        </li>

                                        <li>
                                            <a href="#"><i class="icofont-youtube-play"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i class="icofont-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i class="icofont-twitter"></i></a>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div class="blogsidebar__content__wraper__2" >

                            <h4 class="sidebar__title">Search here</h4>
                            <form action="#">
                                <div class="blogsudebar__input__area">
                                    <input type="text" name="search" placeholder="Search product"/>
                                    <button class="blogsidebar__input__icon">
                                        <i class="icofont-search-1"></i>
                                    </button>


                                </div>


                            </form>

                        </div>
                        <div class="blogsidebar__content__wraper__2" >

                            <h4 class="sidebar__title">categories</h4>
                            <ul class="categorie__list">
                                <li><a href="#">Mobile Set <span>03</span></a></li>
                                <li><a href="#">Mobile Set <span>03</span></a></li>
                                <li><a href="#">Raxila Dish nonyte<span>09</span></a></li>
                                <li><a href="#">Fresh Vegetable <span>01</span></a></li>
                                <li><a href="#">Fruites <span>00</span></a></li>
                                <li><a href="#">Gesuriesey <span>26</span></a></li>
                            </ul>

                        </div>

                        <div class="blogsidebar__content__wraper__2" >

                            <h4 class="sidebar__title">Recent Post</h4>
                            <ul class="recent__list">
                                <li>
                                    <div class="recent__img">
                                        <a href="#">
                                            <img loading="lazy"  src="img/blog/blog_11.png" alt="sidbar"/>
                                            <div class="recent__number">
                                                <span>01</span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="recent__text">

                                        <div class="recent__date">
                                            <a href="#">23 December 2024</a>
                                        </div>

                                        <h6><a href="blog-deetails.html">Show at the University </a></h6>




                                    </div>
                                </li>

                                <li>
                                    <div class="recent__img">
                                        <a href="#">
                                            <img loading="lazy"  src="img/blog/blog_12.png" alt="sidbar"/>
                                            <div class="recent__number">
                                                <span>02</span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="recent__text">

                                        <div class="recent__date">
                                            <a href="#">23 December 2024</a>
                                        </div>

                                        <h6><a href="blog-deetails.html">Show at the University </a></h6>




                                    </div>
                                </li>

                                <li>
                                    <div class="recent__img">
                                        <a href="#">
                                            <img loading="lazy"  src="img/blog/blog_13.png" alt="sidbar"/>
                                            <div class="recent__number">
                                                <span>03</span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="recent__text">

                                        <div class="recent__date">
                                            <a href="#">23 December 2024</a>
                                        </div>

                                        <h6><a href="blog-deetails.html">Show at the University </a></h6>




                                    </div>
                                </li>

                                <li>
                                    <div class="recent__img">
                                        <a href="#">
                                            <img loading="lazy"  src="img/blog/blog_14.png" alt="sidbar"/>
                                            <div class="recent__number">
                                                <span>04</span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="recent__text">

                                        <div class="recent__date">
                                            <a href="#">23 December 2024</a>
                                        </div>

                                        <h6><a href="blog-deetails.html">Show at the University </a></h6>




                                    </div>
                                </li>
                            </ul>

                        </div>

                        <div class="blogsidebar__content__wraper__2" >

                            <h4 class="sidebar__title">Photo Gallery</h4>
                            <div class="photo__gallery__img">

                                <div class="single__gallery__img">
                                    <img loading="lazy"  src="img/blog/blog_15.png" alt="photo"/>
                                    <div class="gallery__icon">
                                        <a class="popup__img" href="img/blog/blog_15.png"> <i class="icofont-eye-alt"></i></a>
                                    </div>
                                </div>
                                <div class="single__gallery__img">
                                    <img loading="lazy"  src="img/blog/blog_16.png" alt="photo"/>
                                    <div class="gallery__icon">
                                        <a class="popup__img" href="img/blog/blog_16.png"> <i class="icofont-eye-alt"></i></a>
                                    </div>
                                </div>
                                <div class="single__gallery__img">
                                    <img loading="lazy"  src="img/blog/blog_17.png" alt="photo"/>
                                    <div class="gallery__icon">
                                        <a class="popup__img" href="img/blog/blog_17.png"> <i class="icofont-eye-alt"></i></a>
                                    </div>
                                </div>
                                <div class="single__gallery__img">
                                    <img loading="lazy"  src="img/blog/blog_18.png" alt="photo"/>
                                    <div class="gallery__icon">
                                        <a class="popup__img" href="img/blog/blog_18.png"> <i class="icofont-eye-alt"></i></a>
                                    </div>
                                </div>
                                <div class="single__gallery__img">
                                    <img loading="lazy"  src="img/blog/blog_19.png" alt="photo"/>
                                    <div class="gallery__icon">
                                        <a class="popup__img" href="img/blog/blog_19.png"> <i class="icofont-eye-alt"></i></a>
                                    </div>
                                </div>
                                <div class="single__gallery__img">
                                    <img loading="lazy"  src="img/blog/blog_20.png" alt="photo"/>
                                    <div class="gallery__icon">
                                        <a class="popup__img" href="img/blog/blog_20.png"> <i class="icofont-eye-alt"></i></a>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="blogsidebar__content__wraper__2" >

                            <h4 class="sidebar__title">Get in Touch</h4>
                            <div class="get__touch__input">
                                <input type="text" placeholder="Enter name*"/>
                                <input type="text" placeholder="Enter your mail*"/>
                                <input type="text" placeholder="Massage*"/>
                                <a class="default__button" href="blog-details.html">SEND MASSAGE</a>
                            </div>

                        </div>

                        <div class="blogsidebar__content__wraper__2" >

                            <h4 class="sidebar__title">Popular tag</h4>
                            <div class="populer__tag__list">
                                <ul>
                                    <li><a href="blog-details.html">Business</a></li>
                                    <li><a href="blog-details.html">Design</a></li>
                                    <li><a href="blog-details.html">apps</a></li>
                                    <li><a href="blog-details.html">landing page</a></li>
                                    <li><a href="blog-details.html">data</a></li>
                                    <li><a href="blog-details.html">book</a></li>
                                    <li><a href="blog-details.html">Design</a></li>
                                    <li><a href="blog-details.html">book</a></li>
                                    <li><a href="blog-details.html">landing page</a></li>
                                    <li><a href="blog-details.html">data</a></li>
                                </ul>
                            </div>

                        </div>

                        <div class="blogsidebar__content__wraper__2" >

                            <h4 class="sidebar__title">Follow Us</h4>
                            <div class="follow__icon">
                                <ul>
                                    <li>
                                        <a href="#"><i class="icofont-facebook"></i></a>
                                    </li>

                                    <li>
                                        <a href="#"><i class="icofont-youtube-play"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i class="icofont-instagram"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i class="icofont-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i class="icofont-instagram"></i></a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
    </>
  )
}

export default BlogSidebar
