import React, { useState } from "react";
import Login from "./component/Login";
import Signup from "./component/Signup";
import ForgotPassword from "./component/ForgotPassword";

function Auth() {
  // State to manage the active tab
  const [activeTab, setActiveTab] = useState("login");

  return (
    <>
      <div className="loginarea sp_top_20 sp_bottom_100">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-md-8 offset-md-2">
              <ul
                className="nav tab__button__wrap text-center"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={`single__tab__link ${
                      activeTab === "login" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("login")}
                    type="button"
                  >
                    Login
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`single__tab__link ${
                      activeTab === "signup" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("signup")}
                    type="button"
                  >
                    Sign up
                  </button>
                </li>
              </ul>
            </div>
            <div className=" tab__content__wrapper" id="myTabContent">
              {activeTab === "login" ? (
                <Login setActiveTab={setActiveTab}/>
              ) : activeTab === "forgotPassword" ? (
                <ForgotPassword setActiveTab={setActiveTab}/>
              ) : (
                <Signup setActiveTab={setActiveTab} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Auth;
