import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../../services/axiosRequestHandler";
import { ERROR_MESSAGE } from "../../../utils/PropertyResolver";
import { API_END_POINT } from "../../../utils/ApiEndPoints";
import toast from "react-hot-toast";

// Initial state for the slice
const jobCategoryState = {
  isLoading: false,
  error: null,
  jobCategoryData: {},
  categoriesData: [],
};

// Creating a Job Category
export const createJobCategory = createAsyncThunk(
  "jobCategory/createJobCategory",
  async (params, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.CREATE_JOB_CATEGORY, params);
      if (response?.status === 200) {
        toast.success(response.response?.data?.message)
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message)
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FAILED_CREATE_JOB_CATEGORY);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FAILED_CREATE_JOB_CATEGORY);
    }
  }
);

// Fetching job categories list
export const getCategoryList = createAsyncThunk(
  "jobCategory/getCategoryList",
  async (_, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.GET_JOB_CATEGORIES_LIST);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FAILED_FETCH_JOB_CATEGORIES);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FAILED_FETCH_JOB_CATEGORIES);
    }
  }
);

// Job Category slice containing both actions
const jobCategorySlice = createSlice({
  name: "jobCategorySlice",
  initialState: jobCategoryState,
  reducers: {
    resetJobCategoryState: (state) => {
      state.jobCategoryData = null;
      state.error = null;
    },
    resetCategoryListState: (state) => {
      state.categoriesData = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Handle createJobCategory actions
    builder
      .addCase(createJobCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createJobCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobCategoryData = action.payload || null;
      })
      .addCase(createJobCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.jobCategoryData = null;
        state.error = action.payload || ERROR_MESSAGE.FAILED_CREATE_JOB_CATEGORY;
      });

    // Handle getCategoryList actions
    builder
      .addCase(getCategoryList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategoryList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categoriesData = action.payload || [];
      })
      .addCase(getCategoryList.rejected, (state, action) => {
        state.isLoading = false;
        state.categoriesData = [];
        state.error = action.payload || ERROR_MESSAGE.FAILED_FETCH_JOB_CATEGORIES;
      });
  },
});

export const { resetJobCategoryState, resetCategoryListState } = jobCategorySlice.actions;

export default jobCategorySlice.reducer;
