import React from 'react'
import LessonsForm from './component/LessonsForm'

function CreateLessons() {
  return (
    <>
      <LessonsForm />
    </>
  )
}

export default CreateLessons
