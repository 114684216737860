import React, { useState, useEffect } from "react";

export default function ComingSoon() {
  const calculateTimeLeft = () => {
    const difference = +new Date("2025-06-01") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup the timer on component unmount
  }, []);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <div className="count" key={interval}>
        <p>{timeLeft[interval]}</p>
        <span>{interval.charAt(0).toUpperCase() + interval.slice(1)}</span>
      </div>
    );
  });

  return (
    <>
      <div className="maintaince" style={{ marginTop: "-200px" }}>
        <div className="container">
          <div className="row align-items-center text-center">
            <div className="maintenance__wrapper">
              <h3>Sorry, We are</h3>
              <h1>Under Process</h1>
              <p>
                We're currently working on it, if all goes as planned we'll be
                back in
              </p>

              <div className="featurearea__countdown justify-content-center">
                {timerComponents.length ? timerComponents : <span>Time's up!</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
