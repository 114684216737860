import * as Yup from "yup";

export const courseValidationSchema = Yup.object({
  title: Yup.string()
    .required("Course Title is required")
    .min(2, "Course Title must be at least 2 characters")
    .max(100, "Course Title must be less than 100 characters"),

  description: Yup.string()
    .required("Description is required")
    .min(10, "Description must be at least 10 characters")
    .max(1000, "Description must be less than 1000 characters"),

  price: Yup.number()
    .required("Price is required")
    .positive("Price must be a positive number")
    .min(0, "Price cannot be less than 0"),

  previousPrice: Yup.number()
    .nullable()
    .positive("Previous Price must be a positive number")
    .min(0, "Previous Price cannot be less than 0"),

  thumbnailUrl: Yup.mixed()
    .required("Thumbnail URL is required")
    .test("fileType", "Unsupported File Format", (value) => {
      if (typeof value === "string") return true; // For URL
      return ["image/jpeg", "image/png", "image/gif"].includes(value?.type); // For file uploads
    }),

  categoryId: Yup.string().required("Category is required"),
  skillLevelId: Yup.number()
    .required("Skill Level is required")
    .positive("Skill Level ID must be a positive number")
    .integer("Skill Level ID must be an integer"),

  instructorId: Yup.number()
    .required("Instructor is required")
    .positive("Instructor ID must be a positive number")
    .integer("Instructor ID must be an integer"),

  profile: Yup.mixed()
    .nullable()
    .test("fileType", "Unsupported File Format", (value) => {
      return (
        !value || ["image/jpeg", "image/png", "image/gif"].includes(value?.type)
      ); 
    }),
});
