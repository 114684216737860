import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { registerUser } from "../../../redux/slices/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { registerUserValidationSchema } from "../../../validationSchema/registerUser";
import ButtonLoader from "../../../shared-components/loader/ButtonLoader";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

function Signup({ setActiveTab }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);
  
  // State for password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("first_name", values?.firstName);
    formData.append("last_name", values?.lastName);
    formData.append("email", values?.email);
    formData.append("password", values?.password);
    formData.append("profile", values.profile);
    
    try {
      await dispatch(registerUser(formData)).unwrap();
      resetForm();
      setActiveTab("login");
    } catch (error) {
      // Handle error
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  return (
    <div className="tab-pane" id="projects__two" role="tabpanel" aria-labelledby="projects__two">
      <div className="col-xl-8 offset-md-2">
        <div className="loginarea__wraper">
          <div className="login__heading">
            <h5 className="login__title">Sign Up</h5>
          </div>

          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              confirmPassword: "",
              profile: null,
              acceptTerms: false,
            }}
            validationSchema={registerUserValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="row">
                  {/* First Name */}
                  <div className="col-xl-6">
                    <div className="login__form">
                      <label className="form__label">
                        First Name<span className="error">*</span>
                      </label>
                      <Field className="common__login__input" name="firstName" placeholder="First Name" />
                      <ErrorMessage name="firstName" component="div" className="error" />
                    </div>
                  </div>

                  {/* Last Name */}
                  <div className="col-xl-6">
                    <div className="login__form">
                      <label className="form__label">
                        Last Name<span className="error">*</span>
                      </label>
                      <Field className="common__login__input" name="lastName" placeholder="Last Name" />
                      <ErrorMessage name="lastName" component="div" className="error" />
                    </div>
                  </div>

                  {/* Email */}
                  <div className="col-xl-6">
                    <div className="login__form">
                      <label className="form__label">
                        Email<span className="error">*</span>
                      </label>
                      <Field className="common__login__input" type="email" name="email" placeholder="Your Email" />
                      <ErrorMessage name="email" component="div" className="error" />
                    </div>
                  </div>

                  {/* Password */}
                  <div className="col-xl-6">
                    <div className="login__form">
                      <label className="form__label">
                        Password<span className="error">*</span>
                      </label>
                      <div className="password__input__wrapper" style={{ position: "relative" }}>
                        <Field
                          className="common__login__input"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                        />
                        <span
                          className="password__toggle"
                          onClick={togglePasswordVisibility}
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                        >
                          <i className={showPassword ? "icofont-eye" : "icofont-eye-blocked"}></i>
                        </span>
                      </div>
                      <ErrorMessage name="password" component="div" className="error" />
                    </div>
                  </div>

                  {/* Confirm Password */}
                  <div className="col-xl-6">
                    <div className="login__form">
                      <label className="form__label">
                        Re-Enter Password<span className="error">*</span>
                      </label>
                      <div className="password__input__wrapper" style={{ position: "relative" }}>
                        <Field
                          className="common__login__input"
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          placeholder="Re-Enter Password"
                        />
                        <span
                          className="password__toggle"
                          onClick={toggleConfirmPasswordVisibility}
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                        >
                          <i className={showConfirmPassword ? "icofont-eye" : "icofont-eye-blocked"}></i>
                        </span>
                      </div>
                      <ErrorMessage name="confirmPassword" component="div" className="error" />
                    </div>
                  </div>

                  {/* Profile upload field */}
                  <div className="col-xl-6">
                    <div className="login__form">
                      <label className="form__label">
                        Upload Profile<span className="error">*</span>
                      </label>
                      <div
                        className="common__login__input"
                        id="fileUpload"
                        onClick={() => document.getElementById("fileInput").click()} // trigger click on file input
                        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                      >
                        <i className="icofont-cloud-upload" style={{ fontSize: "24px", marginRight: "8px" }}></i>
                        <span>Click to upload</span>
                      </div>
                      <input
                        id="fileInput"
                        type="file"
                        name="profile"
                        hidden
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                
                          // Validate file type inline
                          if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
                            setFieldValue("profile", event.currentTarget.files[0])
                          } else {
                            toast.error('Please select a valid image file (JPG or PNG)');
                          }
                        }}
                      />
                      {values?.profile && (
                        <img src={URL.createObjectURL(values.profile)} alt="Thumbnail Preview" width="100" />
                      )}
                      <ErrorMessage name="profile" component="div" className="error" />
                    </div>
                  </div>
                </div>

                <div className="login__form d-flex justify-content-between flex-wrap gap-2">
                  <div className="form__check">
                    <Field type="checkbox" name="acceptTerms" id="accept_pp" />
                    <label htmlFor="accept_pp">
                      Accept the <Link to={""} className="signup-link">Terms</Link> and <Link to={""} className="signup-link" style={{ marginRight: "5px" }}>Privacy Policy</Link>
                    </label>
                    <ErrorMessage name="acceptTerms" component="div" className="error" />
                  </div>
                </div>

                <div className="login__button">
                  <button
                    className="default__button"
                    type="submit"
                    style={{ width: "100%" }}
                    disabled={isLoading}
                  >
                    {isLoading ? <ButtonLoader /> : "Sign Up"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Signup;
