import React from "react";
import { Link } from "react-router-dom";
import Rating from "../rating/Rating";
import {
  capitalizeFirstLetter,
  convertMinutesToHours,
  formatPrice,
  getRandomClassName,
  truncateString,
} from "../../utils/CommonMethod";
import { CONSTANT_NAME } from "../../utils/PropertyResolver";
import Avatar from "react-avatar";

function CourseCard({ data }) {
  const randomClassName = getRandomClassName();
  return (
    <>
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 grid-item filter1 filter3">
        <div className="gridarea__wraper">
          <div className="gridarea__img">
            <Link to={`/sub-topic/${data?.id}`}>
              <img loading="lazy" src={data?.thumbnail_url} alt="grid" />
            </Link>
            <div className="gridarea__small__button">
              <div className={randomClassName}>
                {capitalizeFirstLetter(data?.category)}
              </div>
            </div>
            <div className="gridarea__small__icon">
              {/* save as a bookMark */}
              <Link to={"/"}>
                <i className="icofont-heart-alt"></i>
              </Link>
            </div>
          </div>
          <div className="gridarea__content">
            <div className="gridarea__list">
              <ul>
                <li>
                  <i className="icofont-book-alt"></i> {data?.total_lessons}{" "}
                  Lesson
                </li>
               
                <li>
                  <i className="icofont-clock-time"></i> {convertMinutesToHours(data?.total_hrs)}
                </li>
              </ul>
            </div>
            <div className="gridarea__heading">
              <h3>
                <Link to={`/sub-topic/${data?.id}`}>
                  {truncateString(
                    data?.title,
                    CONSTANT_NAME.COURSE_TITLE_TRUNCATE_LENGTH
                  )}
                </Link>
              </h3>
            </div>
            <div className="gridarea__price">
              {formatPrice(data?.price)} <del>/ {data?.previous_price}</del>
            </div>
            <div className="gridarea__bottom">
              <Link to={`instructor-profile/${data?.instructor_info?.id}`}>
                <div className="gridarea__small__img">
                  {data?.instructor_info?.profile_url ? (
                    <img
                      loading="lazy"
                      src={data?.instructor_info?.profile_url}
                      alt="grid"
                    />
                  ) : (
                    <Avatar
                      size="30"
                      round="20px"
                      textSizeRatio={1.5}
                      color="#A2A9B6"
                      name={data?.instructor_info?.name}
                      fon
                    />
                  )}

                  <div className="gridarea__small__content">
                    <h6>
                      {capitalizeFirstLetter(data?.instructor_info?.name)}
                    </h6>
                  </div>
                </div>
              </Link>

              <div className="gridarea__star">
                <Rating ratingCount={data?.courseRating || 5} />
                <span>({data?.ratingCount || 5})</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseCard;
