import React from "react";
import { useSelector } from "react-redux";
import { formatDateWithTime } from "../../utils/CommonMethod";

function UserProfile() {
  const { userInfo, isLoading } = useSelector((state) => state.userProfile);

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>My Profile</h4>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-4">
          <div className="dashboard__form">Registration Date</div>
        </div>
        <div className="col-lg-8 col-md-8">
          <div className="dashboard__form">
            {formatDateWithTime(userInfo?.created_at)}
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div className="dashboard__form dashboard__form__margin">
            First Name
          </div>
        </div>
        <div className="col-lg-8 col-md-8">
          <div className="dashboard__form dashboard__form__margin">
            {userInfo?.first_name}
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div className="dashboard__form dashboard__form__margin">
            Last Name
          </div>
        </div>
        <div className="col-lg-8 col-md-8">
          <div className="dashboard__form dashboard__form__margin">
            {userInfo?.last_name}
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div className="dashboard__form dashboard__form__margin">
            Username
          </div>
        </div>
        <div className="col-lg-8 col-md-8">
          <div className="dashboard__form dashboard__form__margin">
            {userInfo?.user_name}
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div className="dashboard__form dashboard__form__margin">Email</div>
        </div>
        <div className="col-lg-8 col-md-8">
          <div className="dashboard__form dashboard__form__margin">
            {userInfo?.email}
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div className="dashboard__form dashboard__form__margin">
            Phone Number
          </div>
        </div>
        <div className="col-lg-8 col-md-8">
          <div className="dashboard__form dashboard__form__margin">
            {userInfo?.profile?.phone_number || "-"}
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div className="dashboard__form dashboard__form__margin">Expert</div>
        </div>
        <div className="col-lg-8 col-md-8">
          <div className="dashboard__form dashboard__form__margin">
            {userInfo?.profile?.skill || "-"}
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div className="dashboard__form dashboard__form__margin">
            Biography
          </div>
        </div>
        <div className="col-lg-8 col-md-8">
          <div className="dashboard__form dashboard__form__margin">
            {userInfo?.profile?.bio || "-"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
