// export const BASE_URL = `http://localhost:3000`;
export const BASE_URL = `https://apnicoding.onrender.com`;


export const API_END_POINT = {
  GET_ALL_COURSES: "/courses",
  GET_SUBTOPIC_BY_COURSE_ID: "/subtopics/subtopic-by-courseId",
  GET_LESSONS_BY_SUBTOPIC_ID: "/lessons/lesson-by-subtopicId",
  REGISTER_USER: "/auth/register",
  LOGIN_USER: "/auth/login",
  CREATE_COURSE: "/courses",
  GET_JOB_CATEGORIES_LIST: "/category/category-list",
  GET_ALL_COURSES_TITLE: "/courses/course-with-title",
  CREATE_SUBTOPIC: "/subtopics",
  GET_ALL_SUBTOPICS: "/subtopics/all-sbutopic",
  CREATE_LESSON: "/lessons",
  GET_USER_PROFILE: "/profile",
  UPDATE_PASSWORD: "/profile/password",
  UPDATE_USER_PROFILE: "/profile/profile",
  UPDATE_USER_SOCIAL_MEDIA: "/profile/social-url",
  PROFILE_IMAGE_UPDATE: "/profile/profile-image",
  GET_POPULAR_COURSE: "/courses/popular-course",
  GET_ASSIGNMENTS_BY_LESSON: "/assignment/assignment-list",
  CREATE_ASSIGNMENT: "/assignment",
  SUBMIT_ASSIGNMENT: "/assignmentsubmit",
  CREATE_RESOURCE: "/resource",
  GET_RESOURCES_LIST: "/resource",
  CREATE_REGISTRATION: "/registration",
  GET_REGISTRATIONS_LIST: "/registration",
  DELETE_REGISTRATION: "/registration",
  GET_ALL_SUBTOPICS_COURSE_ID: "/subtopics/all-subtopic-by-courseId",
  LINK_SEND_TO_FORGOT_PASSWORD:"/auth/forgot",
  RESET_PASSWORD:"/auth/reset",
  RESOURCE_COURSE_LIST_DATA:"/resource/courses",
  RESOURCE_SUBTOPICS_BY_COURSE_NAME:"/resource/subtopics",
  RESOURCE_TECHNOLOGY_LIST:"/resource/technologies"

};
