import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { allCourseList } from "../../../redux/slices/coureses/courseSlice";
import ButtonLoader from "../../../shared-components/loader/ButtonLoader";
import MainLoader from "../../../shared-components/loader/MainLoader";
import { getAllSubTopicByCourseId } from "../../../redux/slices/subTopics/subTopicSlice";
import { getLessonsBySubTopicId } from "../../../redux/slices/lessons/lessonSlice";
import { createAssignment } from "../../../redux/slices/assignment/assignmentSlice";

export default function AssignmentForm() {
  const dispatch = useDispatch();
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [selectedSubtopicId, setSelectedSubtopicId] = useState("");

  const { subTopicByCourseId: subTopics, isLoading: subTopicsLoading } =
    useSelector((state) => state.subTopic);
  const { courseList, isLoading: courseLoading } = useSelector(
    (state) => state.course
  );
  const { lessonsData: lessons, isLoading: lessonsLoading } = useSelector(
    (state) => state.lessons
  );

  const isLoading = subTopicsLoading || courseLoading || lessonsLoading;

  useEffect(() => {
    dispatch(allCourseList());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCourseId) {
      dispatch(getAllSubTopicByCourseId(`?id=${selectedCourseId}`));
    }
  }, [selectedCourseId, dispatch]);

  useEffect(() => {
    if (selectedSubtopicId) {
      dispatch(getLessonsBySubTopicId(`?id=${selectedSubtopicId}`));
    }
  }, [selectedSubtopicId, dispatch]);

  const handleCourseChange = (e, setFieldValue) => {
    const courseId = e.target.value;
    setSelectedCourseId(courseId);
    setFieldValue("course_id", courseId);
    setFieldValue("subtopic_id", ""); // Reset subtopic when course changes
    setFieldValue("lesson_id", ""); // Reset lesson when subtopic changes
  };

  const handleSubtopicChange = (e, setFieldValue) => {
    const subtopicId = e.target.value;
    setSelectedSubtopicId(subtopicId);
    setFieldValue("subtopic_id", subtopicId);
    setFieldValue("lesson_id", ""); // Reset lesson when subtopic changes
  };

  const handleSubmit = async (values, { resetForm }) => {
    const payload = {
      title: values.title,
      url: values.url,
        // course_id: values.course_id,
        subtopic_id: values.subtopic_id,
    //   lesson_id: values.lesson_id,
    };

    try {
      // Handle the form submission logic here
      console.log("Submitted payload:", payload);
      await dispatch(createAssignment(payload))
        .unwrap()
        .then(() => {
          resetForm();
          setSelectedCourseId("");
          setSelectedSubtopicId("");
        });
    } catch (error) {
      // Handle error
    }
  };

  return (
    <>
      {isLoading && <MainLoader />}
      <div className=" " id="assignment__form" role="tabpanel">
        <div className="col-xl-12">
          <div className="loginarea__wraper dashboard__content__wraper">
            <div className="login__heading">
              <h5 className="login__title">Create Assignment</h5>
            </div>

            <Formik
              initialValues={{
                title: "",
                url: "",
                course_id: "",
                subtopic_id: "",
                lesson_id: "",
              }}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Course<span className="error">*</span>
                        </label>
                        <Field
                          as="select"
                          className="common__login__input"
                          name="course_id"
                          onChange={(e) => handleCourseChange(e, setFieldValue)}
                        >
                          <option value="">Select Course</option>
                          {courseList?.map((course) => (
                            <option
                              key={course?.course_id}
                              value={course?.course_id}
                            >
                              {course?.title}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="course_id"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Subtopic<span className="error">*</span>
                        </label>
                        <Field
                          as="select"
                          className="common__login__input"
                          name="subtopic_id"
                          disabled={!selectedCourseId}
                          onChange={(e) =>
                            handleSubtopicChange(e, setFieldValue)
                          }
                        >
                          <option value="">Select Subtopic</option>
                          {subTopics?.map((subTopic) => (
                            <option
                              key={subTopic?.subtopic_id}
                              value={subTopic?.subtopic_id}
                            >
                              {subTopic?.title}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="subtopic_id"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Lesson<span className="error">*</span>
                        </label>
                        <Field
                          as="select"
                          className="common__login__input"
                          name="lesson_id"
                          disabled={!selectedSubtopicId}
                        >
                          <option value="">Select Lesson</option>
                          {lessons?.map((lesson) => (
                            <option
                              key={lesson?.lesson_id}
                              value={lesson?.lesson_id}
                            >
                              {lesson?.title}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="lesson_id"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Assignment Title<span className="error">*</span>
                        </label>
                        <Field
                          className="common__login__input"
                          name="title"
                          placeholder="Assignment Title"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          URL<span className="error">*</span>
                        </label>
                        <Field
                          className="common__login__input"
                          name="url"
                          placeholder="URL"
                        />
                        <ErrorMessage
                          name="url"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="login__button">
                    <button
                      className="default__button"
                      type="submit"
                      style={{ width: "100%" }}
                      disabled={isLoading || isSubmitting}
                    >
                      {isLoading ? <ButtonLoader /> : "Submit Assignment"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
