import React from 'react'
import SubLessonForm from './component/SubLessonForm'

function CreateSublessons() {
  return (
    <>
      <SubLessonForm />
    </>
  )
}

export default CreateSublessons
