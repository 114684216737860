import React, { useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";

import ButtonLoader from "../../../shared-components/loader/ButtonLoader";
import MainLoader from "../../../shared-components/loader/MainLoader";
import { subLessonValidationSchema } from "../../../validationSchema/subLesson";
import { allCourseList } from "../../../redux/slices/coureses/courseSlice";
import { createSubTopic } from "../../../redux/slices/subTopics/subTopicSlice";

function SubLessonForm({  }) {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.subTopic);
  const { courseList: courses, isLoading: coursesLoading } = useSelector(
    (state) => state.course
  );

  useEffect(() => {
    dispatch(allCourseList());
  }, [dispatch]);

  const handleSubmit = async (values,{resetForm}) => {
    console.log(values);
    const payload = {
      title: values.title,
      description: values.description,
      course_id: values.courseId,
    };

    try {
      // Wait for the dispatch to finish
      await dispatch(createSubTopic(payload)).unwrap();
      resetForm()
     
    } catch (error) {
      // Handle error
    }
  };

  return (
    <>
      {coursesLoading && <MainLoader />}
      <div className=" " id="sublesson__form" role="tabpanel">
        <div className="col-xl-12">
          <div className="loginarea__wraper dashboard__content__wraper">
            <div className="login__heading">
              <h5 className="login__title">Create Sub Lesson</h5>
            </div>

            <Formik
              initialValues={{
                title: "",
                description: "",
                courseId: "",
              }}
              validationSchema={subLessonValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Course<span className="error">*</span>
                        </label>
                        <Field
                          as="select"
                          className="common__login__input"
                          name="courseId"
                        >
                          <option value="">Select Course</option>
                          {courses.map((course) => (
                            <option key={course.course_id} value={course.course_id}>
                              {course.title}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="courseId"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="login__form">
                        <label className="form__label">
                          Sub Lesson Title<span className="error">*</span>
                        </label>
                        <Field
                          className="common__login__input"
                          name="title"
                          placeholder="Sub Lesson Title"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="col-xl-12">
                      <div className="login__form">
                        <label className="form__label">
                          Description<span className="error">*</span>
                        </label>
                        <Field
                          as="textarea" 
                          className="common__login__input"
                          name="description"
                          placeholder="Sub Lesson Description"
                          rows="5"
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="login__button">
                    <button
                      className="default__button"
                      type="submit"
                      style={{ width: "100%" }}
                      disabled={isLoading || isSubmitting}
                    >
                      {isLoading ? <ButtonLoader /> : "Submit Sub Lesson"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubLessonForm;
