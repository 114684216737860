import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../../services/axiosRequestHandler";
import { ERROR_MESSAGE } from "../../../utils/PropertyResolver";
import { API_END_POINT } from "../../../utils/ApiEndPoints";
import toast from "react-hot-toast";

// Initial state for the slice
const assignmentState = {
  isLoading: false,
  error: null,
  assignmentData: {},
  assignmentsList: [],
};

// Fetching Assignment List by Lesson ID
export const getAssignmentListBySubtopicId = createAsyncThunk(
  "assignment/getAssignmentListBySubtopicId",
  async (lessonId, thunkAPI) => {
    try {
      const response = await GET(
        `${API_END_POINT.GET_ASSIGNMENTS_BY_LESSON}/${lessonId}`
      );
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

// Creating a new Assignment
export const createAssignment = createAsyncThunk(
  "assignment/createAssignment",
  async (payload, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.CREATE_ASSIGNMENT, payload);
      if (response?.status === 200) {
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

export const assignmentSubmit = createAsyncThunk(
  "assignment/assignmentSubmit",
  async (payload, thunkAPI) => {
    try {
      // Prepare headers for multipart/form-data
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      const response = await POST(
        API_END_POINT.SUBMIT_ASSIGNMENT,
        payload,
        headers
      );
      if (response?.status === 200) {
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(
          response.response?.data?.data?.message || ERROR_MESSAGE.SOMETHING_WENT_WRONG
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

// Assignment slice containing both actions
const assignmentSlice = createSlice({
  name: "assignmentSlice",
  initialState: assignmentState,
  reducers: {
    resetAssignmentState: (state) => {
      state.assignmentData = null;
      state.error = null;
    },
    resetAssignmentsListState: (state) => {
      state.assignmentsList = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Handle getAssignmentListByLessonId actions
    builder
      .addCase(getAssignmentListBySubtopicId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssignmentListBySubtopicId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.assignmentsList = action.payload || [];
      })
      .addCase(getAssignmentListBySubtopicId.rejected, (state, action) => {
        state.isLoading = false;
        state.assignmentsList = [];
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      });

    // Handle createAssignment actions
    builder
      .addCase(createAssignment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAssignment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.assignmentData = action.payload || null;
      })
      .addCase(createAssignment.rejected, (state, action) => {
        state.isLoading = false;
        state.assignmentData = null;
        state.error = action.payload || ERROR_MESSAGE.FAILED_CREATE_ASSIGNMENT;
      })
      .addCase(assignmentSubmit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignmentSubmit.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(assignmentSubmit.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      });
  },
});

export const { resetAssignmentState, resetAssignmentsListState } =
  assignmentSlice.actions;

export default assignmentSlice.reducer;
