import * as Yup from "yup";

export const passwordUpdateValidationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required("Current Password is required")
      .min(6, "Password must be at least 6 characters"),
    newPassword: Yup.string()
      .required("New Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Please confirm your password"),
  });